<div class="site-header">

    <div class="site-header__middle container" style="z-index: 100100; position: relative;">
        <div class="site-header__logo">
            <a routerLink="/">
                <img src="/assets/images/logos/voordeelvloeren.svg" alt="Logo voordeelvloeren" width="200" height="39">
            </a>
        </div>
        <div class="site-header__search">
            <app-header-search></app-header-search>
        </div>


        <div class="indicator" style="float: right; margin-left: 180px;">
            <a [routerLink]="'/verlanglijstje/'" class="indicator__button">
                <span class="indicator__area2">
                    <app-icon name="heart-20" size="20"></app-icon>
                    <span class="indicator__value">{{wishlist.quantity$|async}}</span>
                </span>
            </a>
        </div>
        <div class="site-header__phone">


            <div class="indicator indicator--trigger--click" appDropdown="indicator--opened"
                #cartDropdown="appDropdown">
                <a class="indicator__button" (click)="$event.preventDefault(); cartDropdown.toggle()"
                    aria-label="Winkelmand">
                    <span class="indicator__area">
                        <div style="text-align: left; font-size: 14px; padding-right: 10px; line-height: 1;">Bekijk
                            jouw<br><strong>Winkelmand</strong></div>
                        <app-icon name="cart-20" size="20"></app-icon>
                        <span class="indicator__value">{{ cart.quantity$|async }}</span>
                    </span>
                </a>
                <div class="indicator__dropdown">
                    @defer {
                    <app-header-dropcart (closeMenu)="cartDropdown.close()"></app-header-dropcart>
                    }
                </div>
            </div>
        </div>
    </div>
    <div [class.show]="dropdownShown"  class="overlay"></div>
    <div class="site-header__nav-panel">


        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'"
            [search]="layout === 'compact'" ngSkipHydration></app-header-nav>

    </div>
</div>



<!--

<div class="site-header__middle container" style="height: 53px; padding-top: 15px; ">
    <div class="container">
        <div class="row">
            <div class="col" style="max-width: 320px; padding-left: 0px; padding-top: 2px;">
                <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" rel="nofollow"
                    target="_blank">
                    <div style="float: left; padding-top: 3px;">
                        <img src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: top;"
                            width="16" height="16">
                        <img src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: top;"
                            width="16" height="16">
                        <img src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: top;"
                            width="16" height="16">
                        <img src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: top;"
                            width="16" height="16">
                        <img src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: top;"
                            width="16" height="16">
                    </div>
                </a>

                <div style="float: left; padding-top: 1px;">&nbsp;<a
                        href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" target="_blank"
                        rel="nofollow" class="blacklink">
                        <ng-container *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container> / <ng-container
                            *ngIf="fbcreviews">{{fbcreviews['total']}}</ng-container> van
                        <ng-container *ngIf="fbcreviews">{{fbcreviews['count']}}</ng-container> beoordelingen
                    </a></div>


            </div>
            <div class="col" style="text-align: center; padding: 5px 0px 0px 0px;">
                <div class="container">
                    <div class="row">
                        <ul class="inline-list" style="line-height: 16px; ">
                            <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                    routerLink="/klantenservice/betaalmogelijkheden">
                                    <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;">
                                    </div>
                                    iDeal <span class="orangebold">5% korting</span>
                                </a>
                            </li>
                            <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                    routerLink="/klantenservice/retourneren">
                                    <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;">
                                    </div>
                                    <span class="orangebold">Gratis</span> retourneren
                                </a>
                            </li>
                            <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                    routerLink="/klantenservice/bezorgen-afhalen">
                                    <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;">
                                    </div>
                                    <span class="orangebold">Bezorging</span> tot achter de deur
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col"
                style="max-width: 260px; background-image: url('/assets/images/global/klantenservice.jpg'); background-size: 50px; background-repeat: no-repeat; background-position: 220px center; padding: 2px 0px 0px 0px; ">
                <a routerLink="/klantenservice/showroom"><strong>Showroom</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;<a
                    routerLink="/klantenservice"><strong>Klantenservice</strong></a>
            </div>
        </div>
    </div>
</div>

<div class="border-bottom" style="max-width: 1250px; margin: auto;"></div> -->
