import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Optional, Inject } from '@angular/core';
import { RESPONSE } from '../../../express.tokens';
import { Response } from 'express';
//import { isPlatformServer } from '@angular/common';

@Component({
    selector: 'app-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
    constructor(
        //      @Optional() @Inject(REQUEST) private request: Request,
        @Optional() @Inject(RESPONSE) private response: Response,
        //@Inject(PLATFORM_ID) private platformId: any,
        //@Optional() @Inject(RESPONSE) private response: any,

        private titleService: Title,
        private metaService: Meta
    ) {}

    ngOnInit(): void {
        //window.location.href = 'https://api.voordeelvloeren.nl/404.php';
        //console.log(this.platformId)
        //console.log('404-1a');
        //this.request.res.status(404);
        // if (isPlatformServer(this.platformId)) {
        //     console.log('404-2');
        //     if (this.request.res) {
        //         console.log('404-3');
        //         this.request.res.status(404);
        //     }
        // }
        // if (isPlatformServer(this.platformId)) {
        //     console.log('404-1b');
        //     this.response.status(404);
        // }

        this.response?.status(404);

        this.titleService.setTitle('Pagina niet gevonden | Voordeelvloeren');
        this.metaService.updateTag({
            name: 'description',
            content:
                'De pagina is niet gevonden. Misschien is de pagina verplaatst of verwijderd. 404'
        });
        // this.metaService.updateTag({
        //     name: 'robots',
        //     content: 'noindex'
        // });
    }
}
