<div class="block">
    <div class="container">
        <div class="not-found">
            <div class="not-found__404">
                Oeps!
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">De pagina is niet gevonden</h1>

                <p class="not-found__text">

                    Misschien is de pagina verplaatst of verwijderd.
                </p>


                <p class="not-found__text">
                    Klik hier om naar de homepage te gaan.

                </p>

                <a class="btn btn-secondary btn-sm" routerLink="/">Ga naar Homepage</a>
            </div>
        </div>
    </div>
</div>
