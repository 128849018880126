// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//     enableProdMode();
// }

// document.addEventListener('DOMContentLoaded', () => {
//     platformBrowserDynamic()
//         .bootstrapModule(AppModule)
//         .catch((err) => console.error(err));
// });

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

import { AppComponent } from './app/app.component';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);
