<div class="mobile-header__search" appDropdown="mobile-header__search--opened" #search="appDropdown"
    style="z-index: 1000;">
    <form class="mobile-header__search-form" action="/zoek">
        <input class="mobile-header__search-input" (input)="onSearchChange($event.target.value)" id="#queryHeaderMobile"
            name="zoekterm" placeholder="Waar ben je naar op zoek" aria-label="Site search" type="text"
            autocomplete="off" value="{{ zoekterm }}" (keydown.escape)="search.close()" #queryHeaderMobile>
        <button class="mobile-header__search-button mobile-header__search-button--submit" type="submit">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        <button class="mobile-header__search-button mobile-header__search-button--close" type="button"
            (click)="search.close(); ">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>
        <div class="mobile-header__search-body"  ></div>

    </form>
    <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px; margin-bottom: 4px;"
    *ngIf="titlesuggestions">Suggesties
</div>
    <div style="padding: 0px 0px 0px 16px;"  #suggestions></div>


    <div *ngFor="let product of heroes" style="padding: 0px 0px 5px 7px;">

        - <span (click)="open(product.complete)" style="cursor: pointer;"><span
                style="color: #ff7100;">{{product.name2}}</span>{{product.name}}</span>

    </div>
    <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
        *ngIf="titleproducts">Producten</div>

        <div *ngFor="let product of heroes2">

            <div style="padding: 5px 0px 5px 16px; font-size: 14px; display: flow-root;"><a [routerLink]="product.url" (click)="closewindow(); search.close()" style="color: #000;"><img style="padding: 3px 10px 0px 0px; float: left;"
                        src="{{product.img}}" height="60"><strong>{{product.name}}
                        </strong><br>{{[product.subtitle]}}<br>
                    <div style="float: right; font-size: 15px; padding-right: 5px;">€ {{product.price}}</div>
                </a></div>
        </div>
</div>




<div class="mobile-header__indicators" style="width: 45px;">
    <div class="indicator indicator--mobile-search indicator--mobile d-sm-none">
        <button class="indicator__button" (click)="search.open(); queryHeaderMobile.focus()"  aria-label="Zoeken">
            <span class="indicator__area" style="background: #5e5351;">
                <app-icon name="search-20" size="20"></app-icon>
            </span>
        </button>
    </div>





<!--
    <div #suggestions></div>


    <div *ngFor="let product of heroes" style="padding: 0px 0px 5px 7px;">

        - <span (click)="open(product.complete)"><span
                style="color: #ff7100;">{{product.name2}}</span>xx{{product.name}}</span>

    </div>
    <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
        *ngIf="titleproducts">Producten</div>

    <div #products></div> -->





    <!-- <form class="search__form" action="">
        <input class="search__input" (input)="onSearchChange($event.target.value)" id="queryField" name="queryField"
            placeholder="Waar ben je naar op zoek" aria-label="Site search" type="text" autocomplete="off" style="border-radius: 7px 0px 0px 7px; ">
        <button class="search__button" type="submit"  style="border-radius: 0px 7px 7px 0px; ">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        <div class="search__border"></div>
    </form> -->
    <!-- <div class="dropdown-content"> -->
        <!-- <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
            *ngIf="titlesuggestions">Suggesties
        </div> -->
        <!-- <div class="dropdown-padding"> -->
            <!-- <div #suggestions></div> -->


        <!-- </div> -->
        <!-- <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
            *ngIf="titleproducts">Producten</div>

        <div #products></div> -->
    <!-- </div> -->



</div>


<!-- <div  style="width: 100%; height: 100%; max-height: 100%; margin: 0; padding: 0; background-color:rgba(0, 0, 0, 0.5); background-size:100% 100%; z-index: 1; left: 0px; position: fixed;"></div> -->
