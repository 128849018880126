import { Component, OnInit } from '@angular/core';
//import { navigation } from '../../../../../data/header-navigation';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
//import { DirectionService } from '../../../../shared/services/direction.service';
import { FBCReviewsService } from '../../../../shared/services/fbc-reviews.service';
import { fbcReviews } from '../../../../shared/interfaces/fbc-reviews';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';

@Component({
    selector: 'app-header-handigomteweten',
    standalone: true,
    imports: [CommonModule, RouterLink, MenuComponent],
    templateUrl: './handigomteweten.component.html',
    styleUrls: ['./handigomteweten.component.scss']
})
export class HandigomtewetenComponent implements OnInit {
    // fbcCount: string;
    // fbcTotal: string;
    // fbcScore: string;

    items: NavigationLink[] = [
        {
            label: 'Handig om te weten',
            url: '/',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    //    items: NavigationLink[] = navigation;
    hoveredItem: NavigationLink = null;
    n: number;
    fbcreviews: fbcReviews;

    constructor(
        //private direction: DirectionService,
        private httpService: FBCReviewsService
    ) {}

    async ngOnInit() {
        this.httpService
            .getFBCReviews()
            .subscribe((res) => (this.fbcreviews = res));

        // this.fbcScore = localStorage.getItem('fbc-score');
        // this.fbcTotal = localStorage.getItem('fbc-total');
        // this.fbcCount = localStorage.getItem('fbc-count');
    }

    onItemMouseEnter(item: NavigationLink, event: MouseEvent): void {
        this.n = window.setTimeout(() => {
            this.hoveredItem = item;

            if (!(event.target instanceof HTMLElement)) {
                return;
            }

            const element: HTMLElement = event.target;
            const megamenu = element.querySelector(
                '.nav-links__megamenu'
            ) as HTMLElement;

            if (!megamenu) {
                return;
            }

            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;

            // if (this.direction.isRTL()) {
            //     const itemPosition =
            //         containerWidth - (element.offsetLeft + element.offsetWidth);
            //     const megamenuPosition = Math.round(
            //         Math.min(itemPosition, containerWidth - megamenuWidth)
            //     );

            //     megamenu.style.right = megamenuPosition + 'px';
            // } else {
            const itemPosition = element.offsetLeft;
            const megamenuPosition = Math.round(
                Math.min(itemPosition, containerWidth - megamenuWidth)
            );

            megamenu.style.left = megamenuPosition + 'px';
            //}

            document.getElementById('whitescreen').style.display = 'block';
            document.getElementById('blackscreen').className = 'fadeshow';
        }, 0);
    }

    onItemMouseLeave(item: NavigationLink): void {
        clearTimeout(this.n);

        document.getElementById('whitescreen').style.display = 'none';
        document.getElementById('blackscreen').className = 'fadehide';

        if (this.hoveredItem === item) {
            this.hoveredItem = null;
        }
    }

    onSubItemClick(): void {
        this.hoveredItem = null;
    }
}
