import { Routes, Data, ResolveData } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageProductComponent } from './modules/shop/pages/page-product/page-product.component';
import { ProductResolverService } from './modules/shop/resolvers/product-resolver.service';
import { PageCartComponent } from './modules/shop/pages/page-cart/page-cart.component';
import { CategoryResolverService } from './modules/shop/resolvers/category-resolver.service';
//import { ProductsListResolverService } from './modules/shop/resolvers/products-list-resolver.service';
import { SearchListResolverService } from './modules/shop/resolvers/search-list-resolver.service';
import { PageSearchComponent } from './modules/shop/pages/page-search/page-search.component';
import { PageSitemapComponent } from './modules/site/pages/page-sitemap/page-sitemap.component';
import { PageFaqComponent } from './modules/site/pages/page-faq/page-faq.component';
import { PageCompareComponent } from './modules/shop/pages/page-compare/page-compare.component';
import { PageOrderSuccessComponent } from './modules/shop/pages/page-order-success/page-order-success.component';
import { PagePaymentStatusComponent } from './modules/shop/pages/page-payment-status/page-payment-status.component';
import { PageHongaarsepuntComponent } from './modules/site/pages/page-hongaarsepunt/page-hongaarsepunt.component';
import { PageMeisterLinduraComponent } from './modules/site/pages/page-meisterlindura/page-meisterlindura.component';
import { PageCheckoutComponent } from './modules/shop/pages/page-checkout/page-checkout.component';
import { CheckoutGuard } from './modules/shop/guards/checkout.guard';
import { PageWishlistComponent } from './modules/shop/pages/page-wishlist/page-wishlist.component';
import { PageCombinatieKortingComponent } from './modules/site/pages/page-combinatie-korting/page-combinatie-korting.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const categoryPageDataLaminaat: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'laminaat'
};

const categoryPageDataPVC: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'pvc-vloeren'
};

const categoryPageDataOndervloeren: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'ondervloeren'
};

const categoryPageDataPlinten: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'plinten'
};

const categoryPageDataWandpanelen: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'wandpanelen'
};

const categoryPageDataZoeken: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start',
    productType: 'zoeken'
};

// const categoryPageResolvers: ResolveData = {
//     category: CategoryResolverService,
//     products: ProductsListResolverService
// };

const searchPageResolvers: ResolveData = {
    category: CategoryResolverService,
    products: SearchListResolverService
};

export const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        data: {
            // Header layout. Choose one of ['classic', 'compact'].
            headerLayout: 'classic'
        },

        children: [
            {
                path: '',
                pathMatch: 'full',
                data: {
                    title: 'Voordeelvloeren | Laminaat kopen? Mooie laminaatvloeren',
                    metadesc:
                        'Laminaat en PVC vloeren in laminaatplanken, visgraat of laminaattegels van alle merken. Ze vormen de basis voor ieder huis. Check de laminaatvloeren aanbieding online of in onze showroom'
                },
                component: PageHomeOneComponent
            },
            {
                // product/123
                // product/123/asdadasd
                // product/123/
                path: 'product/:productSlug',
                data: {
                    // Product page layout. Possible values: 'standard', 'columnar', 'sidebar'.
                    layout: 'standard',
                    // Sidebar position. Possible values: 'start', 'end'.
                    // It does not matter if the value of the 'layout' parameter is not 'sidebar'.
                    // For LTR scripts "start" is "left" and "end" is "right".
                    sidebarPosition: 'start'
                    //title:  'asd'+'/ductSlug/ AANPASSEN - Voordeelvloeren',
                    //metadesc: 'Dit is een omschrijving'
                },
                children: [
                    {
                        path: ':productSlugName',
                        component: PageProductComponent,
                        resolve: {
                            product: ProductResolverService
                        }
                    },
                    {
                        path: '',
                        component: PageProductComponent,
                        pathMatch: 'full',
                        resolve: {
                            product: ProductResolverService
                        }
                    }
                ]
            },
            {
                path: 'laminaat',
                loadChildren: () =>
                    import('./modules/shop/shop.module').then(
                        (m) => m.ShopModule
                    ),
                data: categoryPageDataLaminaat
            },
            { path: 'pvc', redirectTo: '/pvc-vloeren', pathMatch: 'full' },
            {
                path: 'pvc-vloeren',
                loadChildren: () =>
                    import('./modules/shop/shop.module').then(
                        (m) => m.ShopModule
                    ),
                data: categoryPageDataPVC
            },
            {
                path: 'ondervloeren',
                loadChildren: () =>
                    import('./modules/shop/shop.module').then(
                        (m) => m.ShopModule
                    ),
                data: categoryPageDataOndervloeren
            },
            {
                path: 'plinten',
                loadChildren: () =>
                    import('./modules/shop/shop.module').then(
                        (m) => m.ShopModule
                    ),
                data: categoryPageDataPlinten
            },
            {
                path: 'wandpanelen',
                loadChildren: () =>
                    import('./modules/shop/shop.module').then(
                        (m) => m.ShopModule
                    ),
                data: categoryPageDataWandpanelen
            },
            {
                path: 'zoek',
                component: PageSearchComponent,
                data: categoryPageDataZoeken,
                resolve: searchPageResolvers
            },
            {
                path: 'faq',
                pathMatch: 'full',
                redirectTo: '/faq/onderwerp/top-10'
            },
            {
                path: 'sitemap',
                pathMatch: 'full',
                component: PageSitemapComponent
            },
            {
                path: 'faq/:faqSlug',
                pathMatch: 'full',
                component: PageFaqComponent
            },
            {
                path: 'faq/onderwerp/:faqSlug',
                pathMatch: 'full',
                component: PageFaqComponent
            },
            {
                path: 'sitemap/:sitemapSlug',
                pathMatch: 'full',
                component: PageSitemapComponent
            },
            {
                path: 'winkelmandje',
                pathMatch: 'full',
                component: PageCartComponent
            },
            {
                path: 'vergelijken',
                pathMatch: 'full',
                component: PageCompareComponent
            },
            {
                path: 'checkout/success',
                pathMatch: 'full',
                component: PageOrderSuccessComponent
            },
            {
                path: 'payment-status',
                pathMatch: 'full',
                component: PagePaymentStatusComponent
            },
            {
                path: 'bestellen',
                component: PageCheckoutComponent,
                canActivate: [CheckoutGuard]
            },
            {
                path: 'verlanglijstje',
                pathMatch: 'full',
                component: PageWishlistComponent
            },

            {
                path: 'combinatie-korting',
                pathMatch: 'full',
                component: PageCombinatieKortingComponent
            },
            {
                path: 'hongaarse-punt',
                pathMatch: 'full',
                component: PageHongaarsepuntComponent
            },
            {
                path: 'meister-lindura',
                pathMatch: 'full',
                component: PageMeisterLinduraComponent
            },
            {
                path: 'site',
                loadChildren: () =>
                    import('./modules/site/site.module').then(
                        (m) => m.SiteModule
                    )
            },
            {
                path: 'klantenservice',
                loadChildren: () =>
                    import(
                        './modules/klantenservice/klantenservice.module'
                    ).then((m) => m.KlantenserviceModule)
            },
            {
                path: 'handige-informatie',
                loadChildren: () =>
                    import(
                        './modules/handige-informatie/handige-informatie.module'
                    ).then((m) => m.HandigeInformatieModule)
            },
            { path: '404', component: PageNotFoundComponent },
            { path: '**', redirectTo: '/404' }
        ]
    }
];
