<div class="container gutter">
    <br>
    <h1>Quick-Step laminaat en COREtec PVC vloeren specialist</h1>
    

    <div *ngIf="this.blackfridayactie" class="mt-1 mb-3  d-none d-md-block">

        <img class="img-fluid" src="/assets/images/actie/blackfriday-1272.jpg">
        <br>
    </div>

    <div *ngIf="this.blackfridayactie" class="mt-1 mb-3  d-none d-sm-block d-md-none">

        <img class="img-fluid" src="/assets/images/actie/blackfriday-608.jpg">
        <br>
    </div>

    <div *ngIf="this.blackfridayactie" class="mt-1 mb-3 d-block d-sm-none">

        <img class="img-fluid" src="/assets/images/actie/blackfriday-374.jpg" style="width: 100%;">
        <br>
    </div>



    <div class="row g-3">
        <div class="col-sm-12 col-md-6 col-lg-3">

            <div class="container_foto vierluik2" routerLink="/pvc-vloeren">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">PVC VLOEREN ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2><br><a routerLink="/pvc-vloeren" class="whitelink">PVC VLOEREN</a></h2>
                    <h4><a routerLink="/pvc-vloeren" class="whitelink">Vinyl klik vloeren zijn sterk in opkomst en
                            makkelijk te leggen</a></h4>
                </article>
            </div>

        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">

            <div class="container_foto vierluik1" routerLink="/laminaat">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">LAMINAAT VLOEREN ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2>&nbsp;<br> <a routerLink="/laminaat" class="whitelink">LAMINAAT</a></h2>
                    <h4><a routerLink="/laminaat" class="whitelink">Net als een houten vloer maar dan
                            praktischer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></h4>
                </article>
            </div>


        </div>

        <div class="col-sm-12 col-md-6 col-lg-3">

            <div class="container_foto vierluik3" routerLink="/ondervloeren">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">ONDERVLOEREN ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2>&nbsp;<br><a routerLink="/ondervloeren" class="whitelink">ONDERVLOEREN</a></h2>
                    <h4><a routerLink="/ondervloeren" class="whitelink">Voor elke situatie is er een geschikte
                            ondervloer</a></h4>
                </article>
            </div>

        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">

            <div class="container_foto vierluik4" routerLink="/plinten">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">PLINTEN ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2><a routerLink="/plinten" class="whitelink">PLINTEN en <br>PROFIELEN</a></h2>
                    <h4><a routerLink="/plinten" class="whitelink">Plakplint, overzetplint of toch een mooie
                            muurplint?</a></h4>
                </article>
            </div>

        </div>




        <div class="col-12">

            <h2>Vloeren met goed advies koop je in Wormerveer bij Voordeelvloeren</h2>

            Ben jij op zoek naar <b>een nieuwe vloer</b>? Wil je precies weten welke materialen je nodig hebt om de
            vloer te leggen? Wil je advies om de vloer professioneel te plaatsen? Dan kan jij 365 dagen per jaar bij ons
            terecht met al je vragen en opmerkingen. Vaak is het antwoord simpel, maar ook met moeilijke vragen staat er
            een adviseur voor je klaar.
            <br><br>

            Een vloer leggen is tegenwoordig best makkelijk. Het handige kliksysteem van <a
                routerLink="/laminaat">laminaat</a> en <a routerLink="/pvc-vloeren">PVC vloeren</a>
            is zo ontwikkeld dat iedereen een vloer kan leggen. Het is werkelijk klik-en-klaar. Er zijn wel een paar
            valkuilen waar je niet in wilt trappen. Het is belangrijk te starten met het lezen van de gebruiksaanwijzing
            of het kijken naar video’s op YouTube. Je bent dan al een beetje op de hoogte voordat je jouw nieuwe vloer
            gaat leggen.
        </div>
        <div class="col-sm-12 col-md-6 col-lg-9 ">
            <div class="p-3 border bg-light waterbestendigmain" style="height: 100%;">


                <div class="waterbestendigchild">
                    <img src="/assets/images/pages/homepage/hydroseal.webp" style="float: right;" width="93"
                        height="93">
                    <span class="headerblueresponsive"
                        style="padding: 45px 0px 0px 0px; font-size: 40px; ">Watervast</span>


                    <strong>Waterbestendig laminaat, de nieuwe trend</strong><br>
                    <br>
                    Geschikt voor iedere kamer in huis en perfect voor de gang, keuken en vochtige ruimtes in jouw
                    woning.
                    <br><br>
                    <span style="float: left; fill: #d1d1d1; padding: 3px 10px 0px 0px; fill: #0090e3;">

                        <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
                            <path
                                d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                            </path>
                        </svg>

                    </span>

                    <a routerLink="/laminaat/waterbestendig"><strong>Bekijk alle waterbestendige
                            vloeren</strong></a>



                </div>



            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <!-- <div class="p-3 border bg-light">Custom column padding</div> -->

            <div class="row">
                <div class="col-md-12">
                    <div class="p-3 square"
                        style="background-image:  url('/assets/images/pages/homepage/home2.webp'); background-size: cover;">
                        <span style=" display: block; color: #fff; font-weight: bold; font-size: 20px; ">Thuis
                            genieten op jouw<br>NIEUWE LAMINAATVLOER</span>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="p-3 border">

                        <span class="headerblueresponsive">PVC of Laminaat</span>

                        <strong>Wat is het verschil in gebruik?</strong><br>
                        PVC heeft een zachter oppervlak dempt het loopgeluid, maar is duurder en minder krasbestendig in
                        vergelijking met laminaat

                        <div style="width: 100%; display: block; height: 16px;"></div>

                        <span style="float: left; fill: #d1d1d1; padding: 5px 10px 0px 0px; fill: #0090e3;">

                            <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
                                <path
                                    d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                                </path>
                            </svg>

                        </span>

                        <a routerLink="/handige-informatie/pvc-of-laminaat-vloer"><strong>Lees volledige
                                vergelijking</strong></a>

                    </div>
                </div>

            </div>
        </div>


        <div style=" width: 100%; text-align: center; clear: both; " class="d-lg-none">
            <Br>
            <div class="container">
                <div class="row" style="border-top: 1px solid #ddd;">

                    <span
                        style="padding: 0px 15px; margin: auto; display: -webkit-box;  position: relative; top: -13px; background: #fff; width: 255px;">
                        <div class="checkmark" style="vertical-align: sub;"></div>&nbsp;<a
                            href="/klantenservice/bezorgen-afhalen" class="black"><span
                                class="orangebold">Bezorging</span>
                            tot achter de deur </a>
                    </span>


                </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-6">
            <div class="hoverboxbrown" routerLink="/laminaat/tegellaminaat">
                <img src="/assets/images/pages/homepage/tegellaminaat.webp" alt="Tegel laminaat" width="1024"
                    height="422"><span style="margin: 7px 0px 0px 14px; ">TEGEL LAMINAAT</span>
            </div>

        </div>


        <div class="col-sm-12 col-lg-6">
            <div class="hoverboxbrown" routerLink="/laminaat/visgraat">
                <img src="/assets/images/pages/homepage/visgraatvloeren.webp" alt="Visgraatvloeren" width="1024"
                    height="422"><span style="padding: 7px 0px 0px 14px; ">VISGRAAT
                    VLOEREN</span>
            </div>

        </div>



        <div class="col-6 col-lg-3">
            <div class="hoverbox border" routerLink="/product/567/onderhoudset"
                style="  text-decoration: none;  color: #0090e3; width: 100%; display: block; text-align: center; padding-top: 30px;">
                <img src="/assets/images/pages/homepage/onderhoudset.webp" width="1024" height="1024"
                    style="padding: 0 20%;" alt="Onderhoudset"><br>Onderhoudset<br><br>
            </div>
        </div>
        <div class="col-6 col-lg-3">
            <div class="hoverbox border" routerLink="/product/566/onderhoudsmiddel-2-liter'"
                style="color: #0090e3; width: 100%; display: block; text-align: center; padding-top: 30px;">
                <img src="/assets/images/pages/homepage/onderhoudsmiddel.webp" width="1024" height="1024"
                    style="padding: 0 20%;" alt="Onderhoudsmiddel"><br>Onderhoudsmiddel<br><br>
            </div>
        </div>
        <div class="col-6 col-lg-3">
            <div class="hoverbox border" routerLink="/product/568/cleanmop"
                style="color: #0090e3; width: 100%; display: block; text-align: center; padding-top: 30px;">
                <img src="/assets/images/pages/homepage/cleanmop.webp" width="1024" height="1024"
                    style="padding: 0 20%;" alt="Cleanmop"><br>Cleanmop<br><br>
            </div>
        </div>
        <div class="col-6 col-lg-3">
            <div class="hoverbox border" routerLink="product/1932/livyn-installatie-tool"
                style="color: #0090e3; width: 100%;  display: block; text-align: center; padding-top: 30px;">
                <img src="/assets/images/pages/homepage/installatie-tool.webp" width="1024" height="1024"
                    style="padding: 0 20%;" alt="Installatie Tool"><br>Installatie Tool<br><br>
            </div>
        </div>



        <div style=" width: 100%; text-align: center; clear: both; " class="d-lg-none">
            <div class="container">
                <div class="row" style="border-top: 1px solid #ddd;">

                    <span
                        style="padding: 0px 15px; margin: auto; display: -webkit-box;  position: relative; top: -13px; background: #fff; width: 270px;">
                        <div class="checkmark" style="vertical-align: sub;"></div>&nbsp;iDeal
                        betaling extra <span class="orangebold">5% korting</span>
                    </span>


                </div>
            </div>
        </div>






        <div class="col-12 col-lg-6">
            <div
                style="min-height: 244px; background-image: url('/assets/images/pages/homepage/bezorgen.webp'); background-size: cover; border: #C0C0C0 1px solid;">
            </div>
        </div>
        <div class="col-12 col-lg-6" style="height: 100%;">

            <div style=" text-align: right;">

                <!-- <div style="height: 7px; display: block;" class="d-lg-none"></div> -->
                <br>
                <span class="headerblueresponsive">ONDERVLOERENWIJZER</span>

                <span style="text-align: justify; display: block;">
                    Laminaat en PVC vloeren moeten altijd gelegd worden op de juiste ondervloer om een vlak en glad
                    oppervlak te
                    creëren voor het <a routerLink="/laminaat">laminaat</a> of <a routerLink="/pvc-vloeren">PVC
                        vloer</a>. Nieuwbouwwoningen of appartementen zijn bijna allemaal
                    voorzien
                    van
                    <a routerLink="/ondervloeren/vloerverwarming">vloerverwarming</a>. De juiste ondervloer zorgt voor
                    optimale warmte geleiding en in een appartement ook
                    geluidsreductie naar de omringende appartementen.
                </span>
                <div style="width: 100%; display: block; height: 16px;"></div>

                <span style="float: right; fill: #d1d1d1; padding: 4px 0px 0px 5px; fill: #0090e3;">

                    <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
                        <path
                            d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                        </path>
                    </svg>

                </span>
                <!-- <a class="d-block d-sm-none" routerLink="/handige-informatie/ondervloerenwijzer"><strong>Bekijk de beste
                        ondervloer voor jou</strong></a>
                <a class="d-none d-sm-block" routerLink="/handige-informatie/ondervloerenwijzer"><strong>De beste
                        ondervloer
                        voor
                        laminaat of
                        PVC</strong></a> -->

                <a routerLink="/handige-informatie/ondervloerenwijzer"><strong>De beste
                        ondervloer
                        voor
                        laminaat of
                        PVC</strong></a>
            </div>
        </div>






        <div class="col-sm-12 col-lg-4">

            <div class="container_foto drieluik1" routerLink="/pvc-vloeren/coretec">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">CORETEC ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2 style="border-bottom: #5e5351 solid 1px;">&nbsp;<br><a routerLink="/pvc-vloeren/coretec"
                            class="brownlink">Coretec</a></h2>
                    <h4><a routerLink="/pvc-vloeren/coretec" class="brownlink">De meest verkochte PVC vloer</a></h4>
                </article>
            </div>

        </div>
        <div class="col-sm-12 col-lg-4">

            <div class="container_foto drieluik2" routerLink="/laminaat/quickstep">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">QUICK STEP DECOREN</span>
                </div>
                <article class="text-left">
                    <h2 style="border-bottom: #5e5351 solid 1px;">&nbsp;<br><a routerLink="/laminaat/quickstep"
                            class="brownlink">Quick Step</a></h2>
                    <h4><a routerLink="/laminaat/quickstep" class="brownlink">Mooiste Quick Step laminaatvloer</a></h4>
                </article>
            </div>

        </div>
        <div class="col-sm-12 col-lg-4">

            <div class="container_foto drieluik3" routerLink="/pvc-vloeren/floorify">
                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">FLOORIFY ASSORTIMENT</span>
                </div>
                <article class="text-left">
                    <h2 style="border-bottom: #5e5351 solid 1px;">&nbsp;<br><a routerLink="/pvc-vloeren/floorify"
                            class="brownlink">Floorify</a></h2>
                    <h4><a routerLink="/pvc-vloeren/floorify" class="brownlink">De meest complete PVC vloer</a></h4>
                </article>
            </div>

        </div>

        <!-- /////////// -->

        <div class="col-12">

            <h2>Koop een vloer van een goed merk</h2>
            Het is een open deur, maar een vloer met een bekend merk zoals <a
                routerLink="/laminaat/quickstep">QuickStep</a>, <a routerLink="/pvc-vloeren/floorify">Floorify</a>, <a
                routerLink="/laminaat/douwes-dekker">Douwes Dekker</a> of
            <a routerLink="/pvc-vloeren/coretec">COREtec</a> is beter dan een merkloze vloer. Fabrikanten van deze
            merken
            vloeren hebben enorm veel ervaring
            in het produceren van vloeren. De extra controles tijdens de productie en de hoge eisen aan de kwaliteit van
            de toplaag van de vloer garanderen een lange levertijd.
            <br><br>
            Koop je een vloer van een bekend merk, dan ben je er zeker van dat de vloer duurzaam is geproduceerd.
            Europese regels verplichten namelijk de duurzame productie van vloeren, dit wordt nauwlettend in de
            gaten gehouden. Daarnaast is er controle op de grondstoffen die gebruikt worden en waar ze vandaag
            komen. De gerecyclede verpakking is ook een duidelijk voorbeeld van de groene keten net zoals het
            transport wat wij zo efficiënt mogelijk toepassen.
            <br><br>

            <h2>Zelfs de allersterkste vloer gaat stuk op de verkeerde ondervloer</h2>
            Een laminaat en PVC vloer bestaat uit een basisplaat waar aan de boven- en onderzijde een afwerklaag op
            is aangebracht. Sterk en slijtvast. Aan de 4 zijden van de plank is een mes of groef aangebracht. Het
            mes past exact in de groef en maakt de verbinding tussen de vloerplanken. De verbinding heeft een soort
            bajonet vorm, die zorgt dat de verbinding in tact blijft.
            <br><br>
            De klikverbinding is de zwakke schakel van de vloer. De verbinding tussen de planken kan enkele malen in
            en uit elkaar geklikt worden en is dan nog steeds sterk. Wat je wilt voorkomen is dat de vloer tijdens
            het lopen kan bewegen. De vloer moet niet op de naden in en uit kunnen veren. Deze beweging overbelast
            de klikverbinding, met schade aan de vloer tot gevolg.

            <br><br>

            Laat je informeren welke <a routerLink="/ondervloeren">ondervloer</a> het beste past bij jouw nieuwe vloer.
            Je kan ook de voordelige
            <a routerLink="/combinatie-korting">combinatie</a> kiezen die wordt aangeboden per vloermerk. Iedere
            ondervloer op deze website is getest op
            drukvastheid. Alle ondervloeren zijn voorzien van certificaten zodat je zekerheid hebt van een goede
            ondervloer.


        </div>





        <div class="col-12 col-lg-6">
            <div style="background-image: url('/assets/images/kanweg/home41.webp');
            background-size: cover;">
                <a routerLink="/klantenservice/showroom" class="text-decoration-none">
                    <div class="container_foto2">

                        <article class="text-left">
                            <h2>Showroom</h2>
                            <h4>Bekijk ons ruime assortiment in onze showroom</h4>
                        </article>
                    </div>
                </a>

                <!-- <article class="row align-items-end" style="position: relative; top: 75%;">
                <div style="background-color: red;">
                    <h2>Showroom</h2>
                    <h4>Bekijk ons ruime assortiment in onze showroom</h4>
                </div>

            </article> -->
            </div>
        </div>



        <div class="col-12 col-lg-6">
            <div style="position: relative; width: 100%;  height: 0; padding-bottom: 56.25%;">

                <script type="application/ld+json">
                    {
                      "@context": "https://schema.org/",
                      "@type": "VideoObject",
                      "name": "Online sinds 1999",
                      "duration": "PT00H01M02S",
                      "uploadDate": "2023-04-17",
                      "thumbnailUrl": "https://www.voordeelvloeren.nl/assets/images/youtube-thumbs/wKOJMLpbvo4.jpg",
                      "description": "Bij Voordeelvloeren ben je aan het juiste adres voor kwaliteitsvloeren tegen een scherpe prijs.",
                      "embedUrl": "https://www.youtube.com/embed/wKOJMLpbvo4"

                    }
                    </script>



                <iframe style=" position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.youtube.com/embed/wKOJMLpbvo4?rel=0" allowfullscreen=""
                title="Verschil laminaat en PVC"
                data-component="[{&quot;name&quot;:&quot;trackYouTubeVideoChangeEvent&quot;,&quot;options&quot;:{&quot;ga&quot;:{&quot;category&quot;:&quot;Visual Presentation Page&quot;,&quot;action&quot;:&quot;featured article video&quot;,&quot;value&quot;:3},&quot;youtubeId&quot;:&quot;wKOJMLpbvo4&quot;},&quot;processed&quot;:true,&quot;id&quot;:&quot;0&quot;}]"></iframe>


                <!-- <iframe style=" position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                    src="https://www.youtube.com/embed/wKOJMLpbvo4" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe> -->

            </div>
            <!-- <a routerLink="/klantenservice/showroom" class="text-decoration-none">
                <div class="container_foto2" style="background-image: url('/assets/images/kanweg/home42.webp');
            background-size: cover;">

                    <article class="text-left">
                        <h2>Persoonlijk advies</h2>
                        <h4>Onze ervaren adviseurs helpen je graag&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                    </article>
                </div>
            </a> -->
        </div>
        <!-- /////////// -->


        <div class="col-12">




            <h2> Bezoek onze showroom in Wormerveer, in de Zaanstreek (Noord-Holland) </h2>
            Vind jij het fijn om hulp te hebben bij het kiezen van een nieuwe vloer? Wil je de vloer vasthouden en
            voelen? Of testen op kwaliteit door er lekker op te stampen? Dat kan! Je vindt in onze <a
                routerLink="/klantenservice/showroom">showroom</a> het
            volledige assortiment van vele bekende merken zoals QuickStep, Meister, COREtec PVC en de nieuwste
            Douwes Dekker laminaat en PVC vloeren bij Voordeelvloeren.

            <br><br>

            Wij hebben altijd de nieuwste folders en brochures voor jou klaar liggen. Ook stalen van verschillende
            ondervloeren kan je direct meenemen, uiteraard voorzien van de beschrijving en de juiste wijze voor het
            plaatsen. En heb je dan nog vragen is er altijd een specialist die je graag helpt met advies of tips om
            een PVC of laminaatvloer te leggen.


            <br><br>


            <h2>De nieuwste vloeren trends van 2025</h2>
            De laatste trends van 2024 voor PVC en laminaatvloeren zet zich door in 2025. Zwart is de kleur die het
            meest gecombineerd wordt in huis en vloeren gaan daar naadloos in mee. Afgelakte zwarte plinten worden
            steeds hoger en sluiten prachtig aan bij zowel grijze als houten vloeren. Ook onderstellen van tafels en
            stoelen zijn vaak zwart. De combinatie met bijna elke vloer is hierdoor mogelijk. Het licht eiken
            laminaat, maar ook de PVC variant in visgraat is nog immens populair. Lichte warmere kleuren zoals
            kersen en essen komen langzaam terug en combineren goed met bronzen accenten.


            <br><br>

            <h2>Bestel je vloer makkelijk online met hulp van onze specialisten</h2>
            Een vloer bestel je niet iedere dag en veranderingen gaan snel. Heb jij vragen over een vloer,
            ondervloer, <a routerLink="/plinten">plinten</a> of hoe je een vloer moet leggen of bestellen. Gebruik
            dan
            de chatknop op de
            website, de Whatsappfunctie op je telefoon of bel even met de <a
                routerLink="/klantenservice/showroom">klantenservice</a>. Gewoon wanneer het jou
            uitkomt. Ook in het weekend is er iemand voor jou beschikbaar van 9:00 tot 21:00 uur. Niet omdat het
            moet, maar omdat het kan. En we doen het graag!
            <br><br>
            Je weet welke vloer je wenst en je kan niet wachten totdat de vloer bij jouw afgeleverd wordt? Dat komt
            goed uit. Bestel dan snel en Voordeelvloeren levert de vloer bij jou thuis af. Precies wanneer jij dat
            wenst en netjes binnen achter de eerste deur. Zodat jij weet dat jouw vloer zorgvuldig is behandelt en
            afgeleverd.

            <br><br>

            En dan stopt de service niet! Kom je wat tegen waar je niet van tevoren over na heb gedacht? Stel jouw
            vraag en wij proberen het voor jou op te lossen. Heb je ondanks je beste berekening een fout gemaakt?
            Wij halen teveel bestelde pakken vloer graag gratis bij je op. En heb je toch een pakket te weinig
            besteld, regelen wij dat ook.

            <br><br>

            We zien je graag in de showroom, lezen en beantwoorden je vragen of zien je bestelling met plezier
            tegemoet.

        </div>
    </div>

</div>
<br>

<app-block-products-carousel header="Eerder bekeken door jou" layout="grid-5" *ngIf="lastViewedProducts.products?.length"
    [loading]="lastViewedProducts.loading" [products]="lastViewedProducts.products" [groups]="lastViewedProducts.groups"
    (groupChange)="groupChange2(lastViewedProducts, $event)"></app-block-products-carousel>
