import { Component, Input } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { RootService } from '../../../../shared/services/root.service';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { RouterLink } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { DropdownDirective } from 'src/app/shared/directives/dropdown.directive';
import { HandigomtewetenComponent } from '../handigomteweten/handigomteweten.component';

@Component({
    selector: 'app-header-nav',
    standalone: true,
    imports: [
        RouterLink,
        MenuComponent,
        IconComponent,
        CommonModule,
        DropdownDirective,
        HandigomtewetenComponent
    ],
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent {
    @Input() departments = true;
    @Input() logo = false;
    @Input() search = false;

    hoveredItem: NavigationLink = null;
    n: number;

    constructor(
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService
    ) {}

    items: NavigationLink[] = [
        {
            label: 'Laminaat',
            url: '/laminaat',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    items2: NavigationLink[] = [
        {
            label: 'PVC vloeren',
            url: '/pvc-vloeren',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    items3: NavigationLink[] = [
        {
            label: 'Ondervloeren',
            url: '/ondervloeren',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    items4: NavigationLink[] = [
        {
            label: 'Plinten en profielen',
            url: '/plinten',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    items5: NavigationLink[] = [
        {
            label: 'Wandpanelen',
            url: '/wandpanelen',
            menu: {
                type: 'megamenu',
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Power Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Engravers',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Wrenches', url: '/shop/catalog' },
                                    {
                                        label: 'Wall Chaser',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pneumatic Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Machine Tools',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Thread Cutting',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chip Blowers',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Sharpening Machines',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Pipe Cutters',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Slotting machines',
                                        url: '/shop/catalog'
                                    },
                                    { label: 'Lathes', url: '/shop/catalog' }
                                ]
                            }
                        ]
                    },
                    {
                        size: 6,
                        items: [
                            {
                                label: 'Hand Tools',
                                url: '/shop/catalog',
                                items: [
                                    { label: 'Laminaat', url: '/shop/catalog' },
                                    { label: 'Handsaws', url: '/shop/catalog' },
                                    { label: 'Knives', url: '/shop/catalog' },
                                    { label: 'Axes', url: '/shop/catalog' },
                                    {
                                        label: 'Multitools',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Paint Tools',
                                        url: '/shop/catalog'
                                    }
                                ]
                            },
                            {
                                label: 'Garden Equipment',
                                url: '/shop/catalog',
                                items: [
                                    {
                                        label: 'Motor Pumps',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Chainsaws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Electric Saws',
                                        url: '/shop/catalog'
                                    },
                                    {
                                        label: 'Brush Cutters',
                                        url: '/shop/catalog'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    ];

    onItemMouseEnter(item: NavigationLink, event: MouseEvent): void {
        // this.n = window.setTimeout(() => {
        //     this.hoveredItem = item;

        //     if (!(event.target instanceof HTMLElement)) {
        //         return;
        //     }

        //     const element: HTMLElement = event.target;
        //     const megamenu = element.querySelector(
        //         '.nav-links__megamenu'
        //     ) as HTMLElement;

        //     if (!megamenu) {
        //         return;
        //     }

        //     const container = megamenu.offsetParent;
        //     const containerWidth = container.getBoundingClientRect().width;
        //     const megamenuWidth = megamenu.getBoundingClientRect().width;

        //     const itemPosition = element.offsetLeft;
        //     const megamenuPosition = Math.round(
        //         Math.min(itemPosition, containerWidth - megamenuWidth)
        //     );

        //     megamenu.style.left = megamenuPosition + 'px';

        //     document.getElementById('whitescreen').style.display = 'block';
        //     document.getElementById('blackscreen').className = 'fadeshow';
        // }, 0);

        this.hoveredItem = item;
        document.getElementById('whitescreen').style.display = 'block';
        document.getElementById('blackscreen').className = 'fadeshow';
    }

    onItemMouseLeave(item: NavigationLink): void {
        // clearTimeout(this.n);
        // document.getElementById('whitescreen').style.display = 'none';
        // document.getElementById('blackscreen').className = 'fadehide';
        //////////////
        if (this.hoveredItem === item) {
            this.hoveredItem = null;
        }
        document.getElementById('whitescreen').style.display = 'none';
        document.getElementById('blackscreen').className = 'fadehide';
    }

    onSubItemClick(): void {
        this.hoveredItem = null;
        document.getElementById('whitescreen').style.display = 'none';
        document.getElementById('blackscreen').className = 'fadehide';
    }
}
