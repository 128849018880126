import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
//import { Router } from '@angular/router';
//import { posts } from '../../../data/blog-posts';
//import { Brand } from '../../shared/interfaces/brand';
import { Observable, Subject, merge } from 'rxjs';
import { ShopService } from '../../shared/api/shop.service';
import { Product } from '../../shared/interfaces/product';
//import { Category } from '../../shared/interfaces/category';
import { BlockHeaderGroup } from '../../shared/interfaces/block-header-group';
import { takeUntil, tap } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
//import { BlocksModule } from '../../modules/blocks/blocks.module';
import { SeoService } from '../../shared/services/seo.service';
import { CustomGlobalConstants } from '../../shared/constants/constants';
import { BlockProductsCarouselComponent } from '../../modules/blocks/block-products-carousel/block-products-carousel.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CartModalService } from 'src/app/shared/services/cartmodal.service';
import { NgOptimizedImage } from '@angular/common';

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<Product[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: Product[];
    groups: ProductsCarouselGroup[];
}

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        BlockProductsCarouselComponent,
        CommonModule,
        RouterLink,
        NgOptimizedImage
    ],
    providers: [ShopService, CartModalService],
    templateUrl: './page-home-one.component.html',
    styleUrls: ['./page-home-one.component.scss']
})
export class PageHomeOneComponent implements OnInit, OnDestroy {
    //constructor(private router: Router) {}

    //export class PageHomeOneComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    //bestsellers$: Observable<Product[]>;
    //brands$: Observable<Brand[]>;
    //popularCategories$: Observable<Category[]>;

    //columnTopRated$: Observable<Product[]>;
    //columnSpecialOffers$: Observable<Product[]>;
    //columnBestsellers$: Observable<Product[]>;

    //    posts = posts;

    //featuredProducts: ProductsCarouselData;
    //latestProducts: ProductsCarouselData;
    lastViewedProducts!: ProductsCarouselData;

    lastViewedArray = [];
    blackfridayactie: boolean;

    constructor(
        //     private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private shop: ShopService,
        private titleService: Title,
        private metaService: Meta,
        private seoService: SeoService
    ) {
        this.blackfridayactie = CustomGlobalConstants.BlackFridayActie;
    }

    ngOnInit(): void {
        //this.bestsellers$ = this.shop.getBestsellers(7);
        //this.brands$ = this.shop.getPopularBrands();
        // this.popularCategories$ = this.shop.getCategoriesBySlug(
        //     [
        //         'power-tools',
        //         'hand-tools',
        //         'machine-tools',
        //         'power-machinery',
        //         'measurement',
        //         'clothes-and-ppe'
        //     ],
        //     1
        // );
        //this.columnTopRated$ = this.shop.getTopRated(3);
        //console.log(this.columnTopRated$);
        //this.columnSpecialOffers$ = this.shop.getSpecialOffers(3);
        //this.columnBestsellers$ = this.shop.getBestsellers(3);

        // this.featuredProducts = {
        //     abort$: new Subject<void>(),
        //     loading: false,
        //     products: [],
        //     groups: [
        //         {
        //             name: 'Alle',
        //             current: true,
        //             products$: this.shop.getFeaturedProducts(null, 8)
        //         },
        //         {
        //             name: 'Laminaat',
        //             current: false,
        //             products$: this.shop.getFeaturedProducts('laminaat', 8)
        //         },
        //         {
        //             name: 'PVC',
        //             current: false,
        //             products$: this.shop.getFeaturedProducts('pvc-vloeren', 8)
        //         },
        //         {
        //             name: 'Ondervloeren',
        //             current: false,
        //             products$: this.shop.getFeaturedProducts('ondervloeren', 8)
        //         },
        //         {
        //             name: 'Overige',
        //             current: false,
        //             products$: this.shop.getFeaturedProducts('overige', 8)
        //         }
        //     ]
        // };

        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('lastViewed')) {
                this.lastViewedArray = JSON.parse(
                    localStorage.getItem('lastViewed')
                );
            }
        }

        this.lastViewedProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'Alle',
                    current: true,
                    products$: this.shop.getLastViewedProducts(
                        null,
                        10,
                        this.lastViewedArray
                    )
                }
            ]
        };

        // this.groupChange(
        //     this.featuredProducts,
        //     this.featuredProducts.groups[0]
        // );

        this.groupChange2(
            this.lastViewedProducts,
            this.lastViewedProducts.groups[0]
        );

        // this.latestProducts = {
        //     abort$: new Subject<void>(),
        //     loading: false,
        //     products: [],
        //     groups: [
        //         {
        //             name: 'All',
        //             current: true,
        //             products$: this.shop.getLatestProducts(null, 8)
        //         },
        //         {
        //             name: 'Power Tools',
        //             current: false,
        //             products$: this.shop.getLatestProducts('power-tools', 8)
        //         },
        //         {
        //             name: 'Hand Tools',
        //             current: false,
        //             products$: this.shop.getLatestProducts('hand-tools', 8)
        //         },
        //         {
        //             name: 'Plumbing',
        //             current: false,
        //             products$: this.shop.getLatestProducts('plumbing', 8)
        //         }
        //     ]
        // };
        // this.groupChange(this.latestProducts, this.latestProducts.groups[0]);
        this.titleService.setTitle(
            'Voordeelvloeren | Ruim assortiment laminaat en PVC vloeren'
        );
        //console.log(this.product);
        this.metaService.updateTag({
            name: 'description',
            content:
                'Voordeelvloeren heeft een breed assortiment in laminaat, PVC vloeren, ondervloeren en plinten. Bestel online en ervaar het zelf.'
        });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl'
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
    //     carousel.loading = true;
    //     carousel.abort$.next();

    //     (group as ProductsCarouselGroup).products$
    //         .pipe(
    //             tap(() => (carousel.loading = false)),
    //             takeUntil(merge(this.destroy$, carousel.abort$))
    //         )
    //         .subscribe((x) => (carousel.products = x));
    // }

    // groupChange2(
    //     carousel: ProductsCarouselData,
    //     group: BlockHeaderGroup
    // ): void {
    //     carousel.loading = true;
    //     carousel.abort$.next();

    //     (group as ProductsCarouselGroup).products$
    //         .pipe(
    //             tap(() => (carousel.loading = false)),
    //             takeUntil(merge(this.destroy$, carousel.abort$))
    //         )
    //         .subscribe((x) => (carousel.products = x));
    // }

    groupChange2(
        carousel: ProductsCarouselData,
        group: BlockHeaderGroup
    ): void {
        if (!carousel || !group) {
            return;
        }

        carousel.loading = true;
        carousel.abort$.next();

        const productsGroup = group as ProductsCarouselGroup;
        if (!productsGroup.products$) {
            carousel.loading = false;
            return;
        }

        productsGroup.products$
            .pipe(
                tap(() => (carousel.loading = false)),
                takeUntil(merge(this.destroy$, carousel.abort$))
            )
            .subscribe((x) => (carousel.products = x));
    }
}
