import {
    Component,
    OnInit,
    Inject,
    CUSTOM_ELEMENTS_SCHEMA,
    PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { HttpClient } from '@angular/common/http';
import { FBCReviewsService } from '../../shared/services/fbc-reviews.service';
import { fbcReviews } from '../../shared/interfaces/fbc-reviews';
import { MobileMenuComponent } from '../../modules/mobile/components/mobile-menu/mobile-menu.component';
import { WishlistModalComponent } from 'src/app/shared/components/wishlistmodal/wishlistmodal.component';
import { GeneralModalComponent } from 'src/app/shared/components/generalmodal/generalmodal.component';
import { MobileHeaderComponent } from 'src/app/modules/mobile/components/mobile-header/mobile-header.component';
import { HeaderComponent } from 'src/app/modules/header/header.component';
//import { SearchComponent } from 'src/app/modules/header/components/search/search.component';
//import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { FooterComponent } from 'src/app/modules/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { GeneralModalService } from 'src/app/shared/services/generalmodal.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CartModalComponent } from 'src/app/shared/components/cartmodal/cartmodal.component';
//import { CartModalService } from 'src/app/shared/services/cartmodal.service';
//import { GTMService } from 'src/app/shared/services/gtm.service';
// import { isPlatformBrowser } from '@angular/common';
//import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        MobileHeaderComponent,
        HeaderComponent,
        //        SearchComponent,
        //        IconComponent
        CommonModule,
        RouterOutlet,
        FooterComponent,
        GeneralModalComponent,
        CartModalComponent,
        MobileMenuComponent,
        WishlistModalComponent,
        RouterLink
    ],
    providers: [
        GeneralModalComponent,
        GeneralModalService,
        BsModalService
        // CartModalService
    ],
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
    headerLayout: 'classic' | 'compact';
    data: string;
    fbcreviews: fbcReviews;
    previousUrl: string;

    constructor(
        //      private httpClient: HttpClient,
        //@Inject(PLATFORM_ID) private platformId: any,
        public router: Router,
        public route: ActivatedRoute,
        private httpService: FBCReviewsService //private _dataLayerService: GTMService, //private titleService: Title
    ) {
        this.route.data.subscribe(
            (data) => (this.headerLayout = data.headerLayout)
        );

        this.previousUrl = '';
    }

    async ngOnInit() {
        this.httpService
            .getFBCReviews()
            .subscribe((res) => (this.fbcreviews = res));
        //this.gtmService.addGtmToDom();
        // this.httpClient
        //     .get('https://api.voordeelvloeren.nl/feedbackcompany.php')
        //     .subscribe((res) => {
        //         localStorage.setItem('fbc-score', JSON.stringify(res['score']));
        //         localStorage.setItem('fbc-total', JSON.stringify(res['total']));
        //         localStorage.setItem('fbc-count', JSON.stringify(res['count']));
        //     });
    }
}

//  const items = localStorage.getItem('cartItems');
