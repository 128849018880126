import {
    Component,
    OnInit,
    Renderer2,
    ViewChild,
    ElementRef
} from '@angular/core';
import {
    FormControl,
    UntypedFormGroup,
    UntypedFormBuilder,
    ReactiveFormsModule
} from '@angular/forms';
import { SolrSearchService } from '../../../../shared/services/solr-search.service';
import { ShopService } from '../../../../shared/api/shop.service';
import { Product } from '../../../../shared/interfaces/product';
import { Observable } from 'rxjs';

import { ActivatedRoute, RouterLink } from '@angular/router';

// import * as xml2js from 'xml2js';
//import xml2js from 'xml2js';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { DropdownDirective } from 'src/app/shared/directives/dropdown.directive';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-mobile-search',
    standalone: true,
    imports: [RouterLink, IconComponent, DropdownDirective, CommonModule],
    templateUrl: './mobile-search.component.html',
    styleUrls: ['./mobile-search.component.scss']
})
/*export class SearchComponent {
    constructor() { }
}
*/
@Injectable({
    providedIn: 'root'
})
export class MobileSearchComponent {
    private url = 'https://api.voordeelvloeren.nl/solr-autocomplete.php?term=';
    private url2 = 'https://api.voordeelvloeren.nl/solr-searchbox.php?id=';

    @ViewChild('suggestions') suggestions: ElementRef;
    //@ViewChild('products') products: ElementRef;
    xml: string;

    zoekterm: string;
    myForm: UntypedFormGroup;
    results: any[] = [];
    public xmlItems: any;
    //public productsx: Product[] = [];
    //public productsx: any;
    //columnBestsellers: [Observable<Product[]>
    //public productsx: Observable<Product>;
    productsx: Product[] = [];
    titlesuggestions: boolean;
    titleproducts: boolean;

    autocomplete: string;
    obj: string;
    heroes: any[] = [];
    heroes2: any[] = [];
    txtstring: string;

    @ViewChild('queryHeaderMobile') queryHeaderMobile: ElementRef;

    //   queryField: FormControl = new FormControl();
    constructor(
        private formBuilder: UntypedFormBuilder,
        public cart: SolrSearchService,
        private httpClient: HttpClient,
        private renderer: Renderer2,
        private shop: ShopService,
        private route: ActivatedRoute
    ) {} /* { this.loadXML(); }



*/

    ngOnInit(): void {
        const params: { [param: string]: string } = {};

        this.route.queryParams.subscribe((params) => {
            this.zoekterm = params['zoekterm'];
        });
    }

    onSearchChange(searchValue: string): void {
        this.heroes = [];
        this.heroes2 = [];
        this.titlesuggestions = false;
        this.titleproducts = false;

        if (searchValue.length > 1) {
            this.httpClient
                .get(this.url + searchValue, { responseType: 'text' })
                .subscribe((response) => {
                    // const parser = new xml2js.Parser({
                    //     strict: false,
                    //     trim: true
                    // });
                    // parser.parseString(response, (err, result) => {
                    //     this.xml = result;
                    // });

                    this.autocomplete = JSON.parse(response);

                    this.suggestions.nativeElement.innerHTML = '';
                    //   this.products.nativeElement.innerHTML = '';

                    if (
                        this.autocomplete &&
                        this.autocomplete['autocomplete'] &&
                        this.autocomplete['autocomplete']['term'] &&
                        this.autocomplete['autocomplete']['term'][0] &&
                        Array.isArray(this.autocomplete['autocomplete']['term'])
                    ) {
                        this.heroes = [];

                        for (
                            let i = 0;
                            i <
                            this.autocomplete['autocomplete']['term'].length;
                            i++
                        ) {
                            this.txtstring = this.autocomplete['autocomplete'][
                                'term'
                            ][i].replace(searchValue, '');

                            if (
                                this.autocomplete['autocomplete']['term'][
                                    i
                                ].search(searchValue) == -1
                            ) {
                                searchValue = '';
                            }

                            //      this.txtstring = "- " + this.xml['SPIDEX']['AUTOCOMPLETE'][0]['TERM'][i].replace(re, '<span style="color: #ff7100;" (click)="open($event, item)">' + searchValue + '</span>');

                            this.heroes.push({
                                name: this.txtstring,
                                name2: searchValue,
                                complete:
                                    this.autocomplete['autocomplete']['term'][i]
                            });
                            //console.log(this.txtstring);
                        }
                        if (
                            this.autocomplete['autocomplete']['term'] !=
                            'LET OP: De zoekopdracht mag niet meer dan 10 zoekwoorden bevatten.'
                        ) {
                            this.suggestions.nativeElement.innerHTML =
                                'Bedoelde je...';
                            this.titlesuggestions = true;
                        }
                    } else {
                        this.titlesuggestions = false;
                    }

                    if (this.autocomplete['docs']['id']) {
                        this.heroes2 = [];
                        for (
                            let i = 0;
                            i < this.autocomplete['docs']['id'].length;
                            i++
                        ) {
                            if (i < 5) {
                                const p: HTMLParagraphElement =
                                    this.renderer.createElement('p');

                                this.httpClient
                                    .get(
                                        this.url2 +
                                            this.autocomplete['docs']['id'],
                                        { responseType: 'text' }
                                    )
                                    .subscribe((response) => {
                                        this.obj = JSON.parse(response);

                                        if (i > 0) {
                                            p.innerHTML =
                                                '<div style="clear: both;  border-bottom: #ccc solid 1px; padding-top: 2px;"></div>';
                                        }
                                        // p.innerHTML =
                                        //     p.innerHTML +
                                        //     '<div style="padding: 5px 0px 5px 16px; font-size: 14px;"><a href="' +
                                        //     this.obj[i]['url'] +
                                        //     '" style="color: #000;"><img style="padding: 3px 10px 0px 0px; float: left;" src="' +
                                        //     this.obj[i]['img'] +
                                        //     '" height="60"><strong>' +
                                        //     this.obj[i]['name'] +
                                        //     '</strong><br>' +
                                        //     this.obj[i]['sub'] +
                                        //     '<br><div style="float: right; font-size: 15px; padding-right: 5px;">€ ' +
                                        //     this.obj[i]['prijs'] +
                                        //     '</div></a></div>';

                                        this.heroes2.push({
                                            name: this.obj[i]['name'],
                                            url: this.obj[i]['url'],
                                            img: this.obj[i]['img'],
                                            subtitle: this.obj[i]['sub'],
                                            price: this.obj[i]['prijs']
                                        });
                                    });

                                //                        p.innerHTML = ' <div style="clear: both;  border-bottom: #ccc solid 1px; padding-top: 2px;"></div><div style="padding: 5px 0px 5px 16px; font-size: 14px;"><a href="#" style="color: #000;"><img style="padding: 3px 10px 0px 0px; float: left;" src="https://www.voordeellaminaatvloeren.nl/img-product/50/quickstep-exquisa-exq-1550.jpg" height="60"><strong>' + this.xml['SPIDEX']['DOCS'][0]['ID'][i] + 'QuickStep Exquisa</strong><br>Leisteen Zwart EXQ 1550<br><div style="float: right; font-size: 15px; padding-right: 5px;">€ 26,08</div></a></div>';

                                this.titleproducts = true;
                                // this.renderer.appendChild(
                                //     this.products.nativeElement,
                                //     p
                                // );
                            }
                        }
                    } else {
                        this.titleproducts = false;
                    }
                });
        } else {
            this.suggestions.nativeElement.innerHTML = '';
        }
    }

    public open(item) {
        //document.getElementById("queryField").value = item;
        const inputValue = ((<HTMLInputElement>(
            document.getElementById('zoekterm')
        )).value = item);
        this.queryHeaderMobile.nativeElement.value = item;
        this.onSearchChange(item);
    }

    closewindow(): void {
        //this.zoekterm = '';
        this.queryHeaderMobile.nativeElement.value = '';
        this.onSearchChange('');
    }
}
