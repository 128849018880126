<div class="search dropdown" style="width: 95%; z-index: 999;">
    <form class="search__form" action="/zoek">
        <input class="search__input" (input)="onSearchChange($event.target.value)" id="zoekterm" name="zoekterm"
            style="border-radius: 7px 0px 0px 7px; " placeholder="Waar ben je naar op zoek" aria-label="Site search"
            value="{{ zoekterm }}" type="text" autocomplete="off" #queryHeaderXs>
        <button class="search__button" type="submit" style="border-radius: 0px 7px 7px 0px;" aria-label="Zoeken">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        <div class="search__border"></div>
    </form>
    <div class="dropdown-content" [style.display]="color">
        <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
            *ngIf="titlesuggestions">Suggesties
        </div>
        <div class="dropdown-padding">
            <div #suggestions></div>
            <div *ngFor="let product of heroes" style="padding-left: 10px;">

                - <span (click)="open(product.complete)" style="cursor: pointer;"><span
                        style="color: #ff7100;">{{product.name2}}</span>{{product.name}}</span>
            </div>
        </div>
        <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
            *ngIf="titleproducts">Producten</div>
        <!-- <div #products></div> -->

        <div *ngFor="let product of heroes2">
            <!-- - <span style="cursor: pointer;" [routerLink]="product.url"><span
                    style="color: #ff7100;">{{product.name2}}</span>{{product.name}}</span> -->

            <div style="padding: 5px 0px 5px 16px; font-size: 14px; display: flow-root;"><a [routerLink]="product.url"
                    (click)="closewindow()" style="color: #000;"><img style="padding: 3px 10px 0px 0px; float: left;"
                        src="{{product.img}}" height="60"><strong>{{product.name}}
                    </strong><br>{{[product.subtitle]}}<br>
                    <div style="float: right; font-size: 15px; padding-right: 5px;">€ {{product.price}}</div>
                </a></div>


        </div>
    </div>
</div>
