<!-- <app-loading-bar></app-loading-bar> -->

<!-- <app-quickview></app-quickview> -->

<!-- mobilemenu -->
@defer {
<app-mobile-menu></app-mobile-menu>
}
<!-- mobilemenu / end -->




<!-- site -->
<div class="site">



    @defer {
    <app-cartmodal></app-cartmodal>
    <app-wishlistmodal></app-wishlistmodal>
    <app-generalmodal></app-generalmodal>
    }
    <!-- mobile site__header -->
    <header class="site__header d-lg-none">
        <app-mobile-header></app-mobile-header>
    </header>
    <!-- mobile site__header / end -->

    <!-- desktop site__header -->
    <header *ngIf="headerLayout" class="site__header d-lg-block d-none"
        style="position: -webkit-sticky; /* Safari */ position: sticky; top: 0; z-index: 99999;">
        <app-header [layout]="headerLayout"></app-header>
    </header>


    <div *ngIf="headerLayout" class="site__header d-lg-block d-none">



        <div class="site-header__middle container" style="height: 53px; padding-top: 15px; ">
            <div class="container">
                <div class="row">
                    <div class="col" style="max-width: 320px; padding-left: 0px; padding-top: 2px;">
                        <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" rel="nofollow"
                            target="_blank">
                            <div style="float: left; padding-top: 3px;">
                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                    style="vertical-align: top;" width="16" height="16">
                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                    style="vertical-align: top;" width="16" height="16">
                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                    style="vertical-align: top;" width="16" height="16">
                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                    style="vertical-align: top;" width="16" height="16">
                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                    style="vertical-align: top;" width="16" height="16">
                            </div>
                        </a>

                        <div style="float: left; padding-top: 1px;">&nbsp;<a
                                href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" target="_blank"
                                rel="nofollow" class="blacklink">
                                <ng-container *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container> / <ng-container
                                    *ngIf="fbcreviews">{{fbcreviews['total']}}</ng-container> van
                                <ng-container *ngIf="fbcreviews">{{fbcreviews['count']}}</ng-container> beoordelingen
                            </a></div>


                    </div>
                    <div class="col" style="text-align: center; padding: 5px 0px 0px 0px;">
                        <div class="container">
                            <div class="row">
                                <ul class="inline-list" style="line-height: 16px; ">
                                    <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                            routerLink="/klantenservice/betaalmogelijkheden">
                                            <div class="checkmark"
                                                style="float: left; margin-top: 2px; margin-right: 4px;">
                                            </div>
                                            iDeal <span class="orangebold">extra voordeel</span>
                                        </a>
                                    </li>
                                    <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                            routerLink="/klantenservice/retourneren">
                                            <div class="checkmark"
                                                style="float: left; margin-top: 2px; margin-right: 4px;">
                                            </div>
                                            <span class="orangebold">Gratis</span> retourneren
                                        </a>
                                    </li>
                                    <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                                            routerLink="/klantenservice/bezorgen-afhalen">
                                            <div class="checkmark"
                                                style="float: left; margin-top: 2px; margin-right: 4px;">
                                            </div>
                                            <span class="orangebold">Bezorging</span> tot achter de deur
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col"
                        style="max-width: 260px; background-image: url('/assets/images/global/klantenservice.jpg'); background-size: 50px; background-repeat: no-repeat; background-position: 220px center; padding: 2px 0px 0px 0px; ">
                        <a routerLink="/klantenservice/showroom"><strong>Showroom</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;<a
                            routerLink="/klantenservice"><strong>Klantenservice</strong></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-bottom" style="max-width: 1250px; margin: auto;"></div>

    </div>

    <!-- desktop site__header / end -->

    <!-- site__body -->
    <div class="site__body">
        <router-outlet></router-outlet>
    </div>
    <!-- site__body / end -->

    <!-- site__footer -->
    <footer class="site__footer">
        <app-footer></app-footer>
    </footer>





    <!-- site__footer / end -->
</div>
<!-- site / end -->
