import { Component, inject, PLATFORM_ID } from '@angular/core';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { CartService } from '../../../../shared/services/cart.service';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

import { isPlatformBrowser } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { MobileSearchComponent } from '../mobile-search/mobile-search.component';
import { SearchXSComponent } from '../search-xs/search-xs.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CustomGlobalConstants } from '../../../../shared/constants/constants';

@Component({
    selector: 'app-mobile-header',
    standalone: true,
    imports: [
        IconComponent,
        MobileSearchComponent,
        SearchXSComponent,
        CommonModule,
        RouterLink
    ],
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    transform: 'translateY(-100%)',
                    opacity: 1
                })
            ),
            transition(':enter', [
                style({ transform: 'translateY(-100%)', opacity: 0 }),
                animate('500ms 500ms ease-out')
            ])
        ])
    ]
})
export class MobileHeaderComponent {
    iDealKortingPercentage: number;

    public messageShownIndex = 0;
    private readonly platform = inject(PLATFORM_ID);

    constructor(
        public menu: MobileMenuService,
        public wishlist: WishlistService,
        public cart: CartService
    ) {
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platform)) {
            setInterval(
                () =>
                    (this.messageShownIndex = (this.messageShownIndex + 1) % 3),
                3500
            );
        }
    }

    // ngAfterViewInit() {
    //     this.animateItems();
    // }

    // ngOnDestroy() {
    //     // Implement OnDestroy lifecycle hook
    //     // this.stopAnimation(); // Stop animation on component destruction
    // }

    // animateItems() {
    //     const items = this.el.nativeElement.querySelectorAll('.text-itemx');
    //     let currentItem = 0;
    //     //const totalAnimationTime = 2500 + 500;

    //     const animateItem = () => {
    //         if (!this.continueAnimation) {
    //             // Step 2: Check the flag
    //             return;
    //         }

    //         const item = items[currentItem];
    //         item.classList.add('animate-in');

    //         setTimeout(() => {
    //             item.classList.remove('animate-in');
    //             item.classList.add('animate-out');

    //             setTimeout(() => {
    //                 item.classList.remove('animate-out');

    //                 currentItem = (currentItem + 1) % items.length;
    //                 if (this.continueAnimation) {
    //                     // Check the flag again before continuing
    //                     animateItem(); // Continue the animation
    //                 }
    //             }, 500);
    //         }, 2500);
    //     };

    //     animateItem(); // Start the animation loop
    // }

    // stopAnimation() {
    //     // Step 3: Provide a method to stop the animation
    //     this.continueAnimation = false; // Stop the animation loop
    // }

    // sleep(ms: number) {
    //     return new Promise((resolve) => setTimeout(resolve, ms));
    // }

    // animateItems() {
    //     const items = this.el.nativeElement.querySelectorAll('.text-itemx');
    //     let currentItem = 0;

    //     const animateItem = async () => {
    //         // Make the function async
    //         while (this.continueAnimation) {
    //             // Use a loop controlled by the flag
    //             const item = items[currentItem];
    //             item.classList.add('animate-in');

    //             await this.sleep(2500); // Wait for the animation-in to finish
    //             item.classList.remove('animate-in');
    //             item.classList.add('animate-out');

    //             await this.sleep(500); // Wait for the animation-out to finish
    //             item.classList.remove('animate-out');

    //             currentItem = (currentItem + 1) % items.length;
    //         }
    //     };

    //     animateItem(); // Start the animation loop
    // }
}
