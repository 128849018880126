import { isPlatformBrowser } from '@angular/common';
import {
    Component,
    Inject,
    NgZone,
    OnInit,
    OnDestroy,
    PLATFORM_ID,
    Renderer2
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterOutlet
} from '@angular/router';
//import { ToastrService } from 'ngx-toastr';
import { filter, first, map } from 'rxjs/operators';
import { CartService } from './shared/services/cart.service';
import { CompareService } from './shared/services/compare.service';
import { CurrencyService } from './shared/services/currency.service';
import { WishlistService } from './shared/services/wishlist.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { environment } from '../environments/environment';
import { GTMService } from './shared/services/gtm.service';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

declare const gtag: Function;
declare let dataLayer: any;

@Component({
    selector: 'app-root',

    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private popupOpenSubscription: Subscription;
    // private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    // private statusChangeSubscription: Subscription;
    // private revokeChoiceSubscription: Subscription;
    // private noCookieLawSubscription: Subscription;

    previousUrl: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        //private toastr: ToastrService,
        private cart: CartService,
        private compare: CompareService,
        private wishlist: WishlistService,
        private zone: NgZone,
        //   private scroller: ViewportScroller,
        private currency: CurrencyService,
        private titleService: Title,
        private metaService: Meta,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private _dataLayerService: GTMService
    ) {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.zone.runOutsideAngular(() => {
        //         this.router.events
        //             .pipe(
        //                 filter((event) => event instanceof NavigationEnd),
        //                 first()
        //             )
        //             .subscribe(() => {
        //                 const preloader =
        //                     document.querySelector('.site-preloader');
        //                 preloader.addEventListener(
        //                     'transitionend',
        //                     (event: TransitionEvent) => {
        //                         if (event.propertyName === 'opacity') {
        //                             preloader.remove();
        //                         }
        //                     }
        //                 );
        //                 preloader.classList.add('site-preloader__fade');
        //             });
        //     });
        // }

        this.previousUrl = '';
    }

    ngOnInit(): void {
        // properties of the CurrencyFormatOptions interface fully complies
        // with the arguments of the built-in pipe "currency"
        // https://angular.io/api/common/CurrencyPipe
        this.currency.options = {
            code: 'EUR',
            display: '€ ',
            digitsInfo: '1.2-2',
            locale: 'en-US'
        };

        // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //         console.log('popuClose');
        //     }
        // );

        // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        //     (event: NgcStatusChangeEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //         console.log(`statusChange: ${JSON.stringify(event)}`);
        //     }
        // );

        // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //         console.log(`revokeChoice`);
        //     }
        // );

        // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        //     (event: NgcNoCookieLawEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //         console.log(`noCookieLaw: ${JSON.stringify(event)}`);
        //     }
        // );
        //
        // const appTitle = this.titleService.getTitle();

        // this.router.events
        //     .pipe(
        //         filter((event) => event instanceof NavigationEnd),
        //         map(() => {

        //             const getmeta = () => {
        //                 if (
        //                     this.activatedRoute.root.firstChild.firstChild
        //                         .firstChild !== null
        //                 ) {
        //                     return this.activatedRoute.root.firstChild
        //                         .firstChild.firstChild;
        //                 }
        //                 if (
        //                     this.activatedRoute.root.firstChild.firstChild !==
        //                     null
        //                 ) {
        //                     return this.activatedRoute.root.firstChild
        //                         .firstChild;
        //                 }

        //                 return this.activatedRoute.root.firstChild.firstChild
        //                     .firstChild;
        //             };

        //             const child = getmeta();

        //             if (child.snapshot.data['title']) {
        //                 this.metaService.updateTag({
        //                     name: 'description',
        //                     content: child.snapshot.data['metadesc']
        //                 });
        //                 return child.snapshot.data['title'];
        //             }
        //             return appTitle;
        //         })
        //     )
        //     .subscribe((ttl: string) => {
        //          this.titleService.setTitle(ttl);
        //     });

        //const appTitle = this.titleService.getTitle();
        //console.dir( this.titleService.getTitle() );
        //console.log("asd");
        //this.titleService.setTitle("asd");

        /*
                this.router.events.subscribe((event) => {
                 //   console.log(this.routinh.);
                    //this.titleService.setTitle(data.title);
                    if ((event instanceof NavigationEnd)) {
                        this.scroller.scrollToPosition([0, 0]);
                    }
                });

                */
        // this.cart.onAdding$.subscribe((product) => {
        //     this.toastr.success(
        //         `"${product.name}" toegevoegd aan de winkelmand!`
        //     );
        // });
        // this.compare.onAdding$.subscribe((product) => {
        //     this.toastr.success(
        //         `"${product.name}" toegevoegd aan vergelijken!`
        //     );
        // });
        // this.wishlist.onAdding$.subscribe((product) => {
        //     this.toastr.success(
        //         `"${product.name}" toegevoegd aan je verlanglijst`
        //     );
        // });

        // if (this.document.getElementById('google-tag-manager')) return;
        // const script = this.renderer.createElement('script');
        // script.id = 'google-tag-manager';
        // script.async = true;
        // script.innerHTML = `
        //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        //   })(window,document,'script','dataLayer','GTM-M6HRJ7D');
        // `;
        // this.renderer.appendChild(this.document.head, script);

        if (isPlatformBrowser(this.platformId)) {
            // this.router.events.subscribe((event) => {
            //     if (event instanceof NavigationEnd) {
            //         const pageUrl = event.urlAfterRedirects;
            //         const pageTitle = this.titleService.getTitle();

            //         window.dataLayer = window.dataLayer || [];
            //         window.dataLayer.push({
            //             event: 'page_view',
            //             pageUrl: pageUrl,
            //             pageTitle: pageTitle,
            //             previousUrl: this.previousUrl
            //         });

            //         // Update previousUrl after pushing the event
            //         this.previousUrl = pageUrl;
            //     }
            // });

            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const pageUrl = event.urlAfterRedirects;
                    const pageTitle = this.titleService.getTitle();

                    this._dataLayerService.logPageView(
                        pageUrl,
                        pageTitle,
                        this.previousUrl
                    );

                    // Update previousUrl after pushing the event
                    this.previousUrl = pageUrl;
                }
            });

            // Inject GTM script
            // if (!this.document.getElementById('google-tag-manager')) {
            //     const script = this.renderer.createElement('script');
            //     script.id = 'google-tag-manager';
            //     script.async = true;
            //     script.src =
            //         'https://www.googletagmanager.com/gtm.js?id=GTM-M6HRJ7D'; // Replace GTM-XXXX with your GTM ID
            //     this.renderer.appendChild(this.document.head, script);
            // }

            const script2 = this.renderer.createElement('script');
            script2.async = true;
            script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-435380935', {'allow_enhanced_conversions':true});
        `;
            this.renderer.appendChild(this.document.head, script2);

            // this.router.events.subscribe((event) => {
            //     if (event instanceof NavigationEnd) {
            //         this.gtmService.pushTag(
            //             'page_view',
            //             'Page',
            //             'View',
            //             event.urlAfterRedirects,
            //             1
            //         );
            //     }
            // });

            // this.router.events.subscribe((event) => {
            //     if (event instanceof NavigationEnd) {
            //         gtag('config', 'G-Y0BL46CPR9', {
            //             page_path: event.urlAfterRedirects
            //         });

            //         dataLayer.push({
            //             event: 'pageview',
            //             page: event.urlAfterRedirects
            //         });
            //     }
            // });
        }
    }

    // trackButtonClickGTM() {
    //     this.gtmService.pushTag(
    //         'button_click',
    //         'Button',
    //         'Click',
    //         'Test Button',
    //         1
    //     );
    // }

    ngOnDestroy() {
        //unsubscribe to cookieconsent observables to prevent memory leaks

        if (this.popupOpenSubscription) {
            this.popupOpenSubscription.unsubscribe();
        }
        // this.popupOpenSubscription.unsubscribe();
        // this.popupCloseSubscription.unsubscribe();

        if (this.initializeSubscription) {
            this.initializeSubscription.unsubscribe();
        }
        //this.initializeSubscription.unsubscribe();
        // this.statusChangeSubscription.unsubscribe();
        // this.revokeChoiceSubscription.unsubscribe();
        // this.noCookieLawSubscription.unsubscribe();
    }
}
