<ul class="nav-links__list" style="width: 100%">
    <li *ngFor="let item of items" class="nav-handigomteweten__item" style="float: right" [ngClass]="{
            'nav-links__item--with-submenu': item.menu,
            'nav-links__item--hover': hoveredItem === item
        }" (mouseenter)="onItemMouseEnter(item, $event)" (mouseleave)="onItemMouseLeave(item)">

        <ng-template #link>
            <span style="float: right; padding-right: 0px;">
                {{ item.label }}

            </span>
        </ng-template>

        <a *ngIf="!item.external" [routerLink]="item.url" [target]="item.target">
            <ng-container [ngTemplateOutlet]="link"></ng-container>
        </a>
        <a *ngIf="item.external" [href]="item.url" [target]="item.target">
            <ng-container [ngTemplateOutlet]="link"></ng-container>
        </a>

        <div *ngIf="item.menu && item.menu.type == 'menu'" class="nav-links__menu">
            <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
        </div>



        <div *ngIf="item.menu && item.menu.type == 'megamenu'"
            class="nav-links__megamenu nav-links__megamenu--size--xl">


            <div class="megamenuhandig">
                <div class="row">
                    <div class="col-12">


                        <table style="max-width: 1300px; width: 100%;" cellspacing="0" cellpadding="5">
                            <tbody>
                                <tr>
                                    <td id="footer"
                                        style="vertical-align: top; padding-top: 18px; padding-bottom: 14px;">
                                        <span class="falldownmenuheader">Handig om te weten</span><br>


                                        <a routerLink="/handige-informatie/pvc-of-laminaat-vloer"
                                            (click)="onSubItemClick()" class="black">Wat is het verschil tussen PVC en
                                            laminaat?</a><br>
                                        <a routerLink="/handige-informatie/welke-ondervloer" (click)="onSubItemClick()"
                                            class="black">Waarom heb je een ondervloer nodig?</a><br>
                                        <a routerLink="/handige-informatie/mooie-plinten" (click)="onSubItemClick()"
                                            class="black">Welke plint past het beste bij jouw vloer? </a><br>
                                        <a routerLink="/handige-informatie/complete-vloer" (click)="onSubItemClick()"
                                            class="black">Zo bestel je
                                            een
                                            complete
                                            vloer</a><br>
                                        <a routerLink="/handige-informatie/ondervloerenwijzer" (click)="onSubItemClick()"
                                            class="black">De juiste ondervloer voor laminaat of PVC</a><br>
                                        <a routerLink="/handige-informatie/over-voordeelvloeren"
                                            (click)="onSubItemClick()" class="black">Voordeelvloeren online vanaf
                                            1999</a><br>
                                        <br>

                                        <span class="falldownmenuheader">Klantenreacties</span><br>

                                        <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
                                            target="_blank" rel="nofollow" class="black">
                                            <div id="stars" style=" float: left; width: 85px;">

                                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                                    style="vertical-align: unset;" width="16" height="16">
                                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                                    style="vertical-align: unset;" width="16" height="16">
                                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                                    style="vertical-align: unset;" width="16" height="16">
                                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                                    style="vertical-align: unset;" width="16" height="16">
                                                <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                                    style="vertical-align: unset;" width="16" height="16">


                                            </div>
                                        </a>


                                        <div style="display: block; float: left;">

                                            <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
                                                target="_blank" rel="nofollow" class="black">



                                                <ng-container *ngIf="fbcreviews">
                                                    <strong>{{fbcreviews['score']}}</strong>
                                                </ng-container> / <ng-container *ngIf="fbcreviews">
                                                    <strong>{{fbcreviews['total']}}</strong>
                                                </ng-container> van
                                                <ng-container *ngIf="fbcreviews">
                                                    <strong>{{fbcreviews['count']}}</strong>
                                                </ng-container>
                                                beoordelingen
                                            </a>

                                        </div>


                                    </td>
                                    <td id="footer" style="vertical-align: top; padding-top: 18px;">
                                        <span class="falldownmenuheader">Ons team staat klaar om te helpen</span><br>
                                        <a routerLink="/klantenservice" (click)="onSubItemClick()"
                                            class="black">Klantenservice</a><br>
                                        <a routerLink="/klantenservice/bezorgen-afhalen" (click)="onSubItemClick()"
                                            class="black">Bezorgen of
                                            afhalen</a><br>
                                        <a routerLink="/klantenservice/retourneren" (click)="onSubItemClick()"
                                            class="black">Retourneren</a><br>
                                        <a routerLink="/klantenservice/betaalmogelijkheden" (click)="onSubItemClick()"
                                            class="black">Betaalmogelijkheden</a><br>
                                        <a routerLink="/faq/onderwerp/top-10" (click)="onSubItemClick()"
                                            class="black">Veel gestelde vragen</a><br>
                                        <a routerLink="/klantenservice/brochures-prijslijsten"
                                            (click)="onSubItemClick()" class="black">Brochures en
                                            prijslijsten</a>



                                    </td>
                                    <td id="footer" style="vertical-align: top; padding-top: 18px;">
                                        <span class="falldownmenuheader">Kijk voor inspiratie op</span><br>
                                        <i class="fab2 fa-facebook-square" style="top: 3px;"></i> <a
                                            href="https://www.facebook.com/voordeelvloeren" target="_blank"
                                            rel="nofollow" class="black">Facebook</a><br>
                                        <i class="fab2 fa-instagram"></i> <a
                                            href="https://www.instagram.com/voordeelvloeren/" target="_blank"
                                            rel="nofollow" class="black">Instagram</a><br>
                                        <i class="fab2 fa-youtube"></i> <a
                                            href="https://www.youtube.com/@voordeelvloeren-zf3ju" target="_blank"
                                            rel="nofollow" class="black">YouTube</a><br>
                                        <i class="fab2 fa-pinterest"></i> <a
                                            href="https://nl.pinterest.com/voorjouwhuis/" target="_blank" rel="nofollow"
                                            class="black">Pinterest</a>
                                        <br><br>

                                        <span class="falldownmenuheader">Deuren en kasten op maat</span><br>
                                        <a href="https://www.voordeeldeuren.nl" target="_blank"
                                            class="black">Voordeeldeuren</a><br>
                                        <a href="https://www.voordeelschuifdeurkast.nl" target="_blank"
                                            class="black">Voordeelkasten</a>
                                    </td>

                                    <td id="footer" style=" vertical-align: top; background-image: url('/assets/images/global/handigomteweten-ondervloer-bg.webp');
                                    background-repeat: no-repeat; background-position: right top;">

                                        <div
                                            style="margin-top: 172px; float: right; width: 230px; padding-bottom: 19px; padding-left: 0px;  margin-right: 20px;  ">

                                            <span style="font-size: 18px;  margin-bottom: 0;
                                                margin-top: 0;
                                                line-height: 1.2;
                                                font-weight: 700; padding-left: 10px; color: #305696;">
                                                ONDERVLOERENWIJZER</span><br>
                                            <div style="padding-left: 10px;  ">
                                                <span
                                                    style=" padding: 4px 10px 0px 0px; fill: #0090e3; float: left;"><svg
                                                        width="9px" viewBox="0 0 63 99" aria-hidden="true"
                                                        focusable="false">
                                                        <path _ngcontent-serverApp-c124=""
                                                            d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                                                        </path>
                                                    </svg></span>
                                                <a routerLink="/handige-informatie/ondervloerenwijzer"
                                                    (click)="onSubItemClick()">Vind de beste
                                                    ondervloer</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>



        </div>
    </li>
</ul>


<!--



<ul class=" nav-links__list">

    <li class="nav-links__item">
        <a href="/laminaat/catalog/power-tools">
            <span>
                Laminaat
            </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item">
        <a href="/pvc/catalog/cordless-screwdrivers">
            <span>
                PVC vloeren
            </span></a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item">
        <a href="#">
            <span>
                Ondervloeren
            </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item">
        <a href="#">
            <span>
                Plinten en profielen
            </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item">
        <a href="#">
            <span>
                Combinatie korting
            </span>
        </a>
    </li>


</ul>
-->
