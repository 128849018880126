import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DropdownService {
    private _dropdownShown = new BehaviorSubject<boolean>(false);
    dropdownShown$ = this._dropdownShown.asObservable();

    showDropdown() {
        this._dropdownShown.next(true);
    }

    hideDropdown() {
        this._dropdownShown.next(false);
    }
}
