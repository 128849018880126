import { Optional, Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Product } from '../../../shared/interfaces/product';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RootService } from '../../../shared/services/root.service';
import { ShopService } from '../../../shared/api/shop.service';

import { RESPONSE } from '../../../../express.tokens';
import { Response } from 'express';

@Injectable({
    providedIn: 'root'
})
export class ProductResolverService implements Resolve<Product> {
    constructor(
        private root: RootService,
        private router: Router,
        private shop: ShopService,
        @Optional() @Inject(RESPONSE) private response: Response
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Product> {
        const productSlug = route.params.productSlug || route.data.productSlug;
        const productSlugName = route.params.productSlugName;
        return this.shop.getProduct(productSlug).pipe(
            tap((product) => {
                if (
                    this.response &&
                    (!productSlugName || productSlugName !== product.slug)
                ) {
                    const urlTree = this.router.createUrlTree([
                        '/product',
                        product.id,
                        product.slug
                    ]);
                    this.response.redirect(301, urlTree.toString());
                }
                //console.log('ProductResolverService.resolve', product);
            }),
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 404
                ) {
                    this.router.navigate([this.root.notFound()]).then();
                }

                return EMPTY;
            })
        );
    }
}
