

<div [innerHTML]="jsonLD"></div>
<div [innerHTML]="jsonLD2"></div>





<div class="row" class="d-lg-none">
    <!-- <hr> -->

    <span class="footerratingouter"><a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
            class="black" target="_blank" rel="nofollow">
            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="18" height="18">
            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="18" height="18">
            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="18" height="18">
            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="18" height="18">
            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="18" height="18">
        </a>&nbsp;<a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" class="black"
            target="_blank">Klantwaardering</a>&nbsp;<span class="orangebold"><ng-container *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container></span></span>
</div>



<div class="d-lg-block d-none">
    <hr>
    <div class="site-header__middle container " style="height: 35px; padding-top: 0px; ">
        <div class="container">
            <div class="row" style="text-align: center;">
                <div class="footerratingouterXL col">
                    <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" target="_blank"
                        rel="nofollow">
                        <div>
                            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="16" height="16">
                            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="16" height="16">
                            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="16" height="16">
                            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="16" height="16">
                            <img src="/assets/images/global/ratingstar.svg" alt="Rating" width="16" height="16">
                        </div>
                    </a>
                    <div style="float: left;  padding-top: 1px;">&nbsp;&nbsp;<a
                            href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
                            target="_blank" class="blacklink" rel="nofollow">
                            <ng-container *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container> / <ng-container
                                *ngIf="fbcreviews">{{fbcreviews['total']}}</ng-container> van
                            <ng-container *ngIf="fbcreviews">{{fbcreviews['count']}}</ng-container> beoordelingen
                        </a></div>

                </div>
                <div class="col" style="margin-top: 15px; padding-left: 8px;">
                    <ul class="inline-list blacklink" style="line-height: 16px;">
                        <li class="inline-list__item inline-list__item--gap-4">
                            <a routerLink="/klantenservice/retourneren" class="blacklink">
                                <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                                <span class="orangebold">Gratis</span> retourneren
                            </a>
                        </li>
                        <li class="inline-list__item inline-list__item--gap-4">
                            <a routerLink="/klantenservice/bezorgen-afhalen" class="blacklink">
                                <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                                <span class="orangebold">Bezorging</span> tot achter de deur
                            </a>
                        </li>
                        <li class="inline-list__item inline-list__item--gap-4">
                            <div class="checkmark" style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                            iDeal <span class="orangebold">{{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}% voordeel</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="site-footer" style="background-color: #f8f4ee;">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 center_form">
                    <app-footer-links header="Handig om te weten" [links]="[
                    {label: 'Wat is het verschil tussen PVC en laminaat?', url: '/handige-informatie/pvc-of-laminaat-vloer'},
                    {label: 'Waarom heb je een ondervloer nodig?', url: '/handige-informatie/welke-ondervloer'},
                        {label: 'Welke plint past het beste bij jouw vloer?', url: '/handige-informatie/mooie-plinten'},
                        {label: 'Zo bestel je een complete vloer', url: '/handige-informatie/complete-vloer'},
                        {label: 'De juiste ondervloer voor laminaat of PVC', url: '/handige-informatie/ondervloerenwijzer'},
                        {label: 'Voordeelvloeren online vanaf 1999', url: '/handige-informatie/over-voordeelvloeren'}

                    ]"></app-footer-links>



                    <!-- <div class="search dropdown d-none d-lg-block" style="width: 100%;">
                        <div [ngClass]="(titlesuggestions || titleproducts)?'dropdown-content':'my-class2'"
                            [style.display]="color">
                            <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
                                *ngIf="titlesuggestions">Producten
                            </div>
                            <div class="dropdown-padding">

                                <div *ngFor="let product of heroes2">

                                    <div style="padding: 5px 0px 5px 0px; font-size: 14px; display: flow-root;"><a
                                            [routerLink]="product.url" (click)="closewindow()" style="color: #000;"><img
                                                style="padding: 3px 10px 0px 0px; float: left;" src="{{product.img}}"
                                                height="60"><strong>{{product.name}}
                                            </strong><br>{{[product.subtitle]}}<br>
                                            <div style="float: right; font-size: 15px; padding-right: 5px;">€
                                                {{product.price}}</div>
                                        </a></div>


                                </div>




                            </div>
                            <div style="background-color: #b4a097; padding: 5px 0px 5px 16px; color: #fff; font-size: 14px;"
                                *ngIf="titleproducts">Suggesties</div>
                            <div style="padding-left: 6px;">
                                <div #suggestions></div>
                                <div *ngFor="let product of heroes">

                                    - <span (click)="open(product.complete)" style="cursor: pointer;"><span
                                            style="color: #ff7100;">{{product.name2}}</span>{{product.name}}</span>

                                </div>
                            </div>

                        </div>

                        <form class="search__form" action="/zoek">
                            <input class="search__input" (input)="onSearchChange($event.target.value)"
                                id="queryFieldFooter" name="zoekterm" placeholder="Waar ben je naar op zoek"
                                aria-label="Site search" type="text" autocomplete="off" value="{{ zoekterm }}"
                                style="width: 100%; border-radius: 7px 0px 0px 7px !important;" #queryFooter>
                            <button class="search__button" type="submit"
                                style="position: relative; border-radius: 7px 7px 7px 0px !important; box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3); left: -2px;"
                                aria-label="Zoeken">
                                <app-icon name="search-20" size="20"></app-icon>
                            </button>

                        </form>

                    </div> -->


                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-links header="Ons team staat klaar om te helpen" [links]="[
                        {label: 'Klantenservice', url: '/klantenservice'},
                        {label: 'Showroom', url: '/klantenservice/showroom'},
                        {label: 'Bezorgen of afhalen',  url: '/klantenservice/bezorgen-afhalen'},
                        {label: 'Retourneren',      url: '/klantenservice/retourneren'},
                        {label: 'Betaalmogelijkheden',     url: '/klantenservice/betaalmogelijkheden'},
                        {label: 'Legservice',     url: '/klantenservice/legservice'},
                        {label: 'Veel gestelde vragen',       url: '/faq/onderwerp/top-10'},
                        {label: 'Brochures en prijslijsten',     url: '/klantenservice/brochures-prijslijsten'}
                    ]"></app-footer-links>
                </div>
                <div class="col-lg-4 d-none d-sm-none d-md-none d-lg-block">

                    <div class="footer-newsletter">
                        <h5 class="footer-links__title">Kijk voor inspiratie op</h5>



                        <ul class="footer-newsletter__social-links">




                            <li class="footer-newsletter__social-link"><a
                                    href="https://www.facebook.com/voordeelvloeren/" target="_blank" rel="nofollow"><i
                                        class="fab1 fa-facebook-square"></i> Facebook</a></li>
                            <li class="footer-newsletter__social-link"><a
                                    href="https://www.instagram.com/voordeelvloeren/" target="_blank" rel="nofollow"><i
                                        class="fab1 fa-instagram"></i> Instagram</a></li>
                            <li class="footer-newsletter__social-link"><a href="https://www.youtube.com/@voordeelvloeren-zf3ju"
                                            target="_blank" rel="nofollow"><i class="fab1 fa-youtube"></i> YouTube</a></li>
                            <li class="footer-newsletter__social-link"><a href="https://nl.pinterest.com/voorjouwhuis/"
                                    target="_blank" rel="nofollow"><i class="fab1 fa-pinterest"></i> Pinterest</a></li>

                        </ul>
                    </div>

                    <br>

                    <a routerLink="/">
                        <img src="/assets/images/logos/voordeelvloeren2.svg" alt="Logo voordeelvloeren" width="220" height="41.55">
                    </a>



                    <!-- <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter> -->
                </div>
            </div>
        </div>
    </div>
    <div class="site-footer__bottom"><a href="https://www.qshops.org/webshop/view/voordeelvloeren-nl" target="_blank"
            rel="nofollow">
            <img src="/assets/images/logos/qshops.svg" width="35" height="35" alt="Qshops logo" /></a>&nbsp;<a
            href="https://www.qshops.org/webshop/view/voordeelvloeren-nl" target="_blank" rel="nofollow">Q-shops
            gecertificeerd </a> &nbsp;|&nbsp; <img src="/assets/images/logos/betaalmethode.png"
            style="vertical-align: middle;" alt="Logo Betaalmogelijkheden" /> <br><a
            routerLink="/klantenservice/algemene-voorwaarden">Alg.
            voorwaarden</a>
        &nbsp;|&nbsp; <a routerLink="/sitemap">Sitemap</a>
        &nbsp;|&nbsp; <a routerLink="/klantenservice/privacy">Privacy</a>
        &nbsp;|&nbsp; © Voordeel<a routerLink="/laminaat">vloeren</a></div>

</div>
