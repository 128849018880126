import {
    Component,
    Renderer2,
    OnInit,
    ElementRef,
    ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as xml2js from 'xml2js';
import { Injectable } from '@angular/core';
import { FBCReviewsService } from '../../shared/services/fbc-reviews.service';
import { fbcReviews } from '../../shared/interfaces/fbc-reviews';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomGlobalConstants } from '../../shared/constants/constants';
import { LinksComponent } from '../footer/components/links/links.component';
import { DecimalPipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [LinksComponent, DecimalPipe, CommonModule, RouterLink],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
@Injectable({
    providedIn: 'root'
})
export class FooterComponent implements OnInit {
    // fbcCount: string;
    // fbcTotal: string;
    // fbcScore: string;

    public fbcreviews: fbcReviews;

    private url = 'https://api.voordeelvloeren.nl/solr-autocomplete.php?term=';
    private url2 = 'https://api.voordeelvloeren.nl/solr-searchbox.php?id=';

    @ViewChild('suggestions') suggestions: ElementRef;
    //@ViewChild('products') products: ElementRef;

    heroes: any[] = [];
    heroes2: any[] = [];
    xml: string;
    titlesuggestions: boolean;
    titleproducts: boolean;
    zoekterm: string;

    autocomplete: string;
    obj: string;
    txtstring: string;
    jsonLD: SafeHtml;
    jsonLD2: SafeHtml;
    color = 'block';
    iDealKortingPercentage: number;

    @ViewChild('queryFooter') queryFooter: ElementRef;

    constructor(
        private httpClient: HttpClient,
        private renderer: Renderer2,
        private httpService: FBCReviewsService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
    }

    async ngOnInit() {
        this.httpService
            .getFBCReviews()

            .subscribe((res) => {
                this.fbcreviews = res;

                const json = {
                    '@context': 'http://schema.org',
                    '@type': 'Organization',
                    '@id': 'https://www.voordeelvloeren.nl',
                    url: 'https://www.voordeelvloeren.nl',
                    logo: 'https://www.voordeelvloeren.nl/assets/images/logos/voordeelvloeren.jpg',
                    name: 'Voordeelvloeren',
                    email: 'info@voordeelvloeren.nl',
                    description:
                        'Voordeelvloeren is de online winkel voor PVC en Laminaat vloeren. Wij zijn dealer van de bekendste fabrikanten zoals Quickstep, Meister, Ambiant, Douwes Dekker en vele anderen. Voordeelvloeren biedt maximale service van bestelling tot aflevering.',
                    image: 'https://www.voordeelvloeren.nl/assets/images/pages/laminaat-pvc-showroom.jpg',
                    address: {
                        '@type': 'PostalAddress',
                        addressLocality: 'Wormerveer, Nederland',
                        postalCode: '1521 RW',
                        streetAddress: 'Rosbayerweg 103'
                    },
                    telephone: '075-6572211'
                };

                const json2 = {
                    '@context': 'http://schema.org',
                    '@type': 'Product',
                    name: 'Voordeelvloeren',
                    image: 'https://www.voordeelvloeren.nl/assets/images/pages/laminaat-pvc-showroom.jpg',
                    description:
                        'Voordeelvloeren heeft een breed assortiment in laminaat, PVC vloeren, ondervloeren en plinten. Bestel online en ervaar het zelf.',
                    brand: {
                        '@type': 'Brand',
                        name: 'Voordeelvloeren'
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: this.fbcreviews['score'].toString(),
                        reviewCount: this.fbcreviews['count'].toString(),
                        bestRating: '10',
                        worstRating: '1'
                    }
                };

                this.jsonLD = this.getSafeHTML(json);

                this.jsonLD2 = this.getSafeHTML(json2);
            });
        //     this.fbcScore = localStorage.getItem('fbc-score');
        //     this.fbcTotal = localStorage.getItem('fbc-total');
        //     this.fbcCount = localStorage.getItem('fbc-count');

        // Basically telling Angular this content is safe to directly inject into the dom with no sanitization

        const params: { [param: string]: string } = {};

        this.route.queryParams.subscribe((params) => {
            this.zoekterm = params['zoekterm'];
        });
    }

    onSearchChange(searchValue: string): void {
        this.suggestions.nativeElement.innerHTML = '';
        //this.products.nativeElement.innerHTML = '';
        this.titlesuggestions = false;
        this.titleproducts = false;
        this.heroes = [];
        this.color = '';

        if (searchValue.length > 1) {
            this.httpClient
                .get(this.url + searchValue, { responseType: 'text' })
                .subscribe((response) => {
                    // const parser = new xml2js.Parser({
                    //     strict: false,
                    //     trim: true
                    // });
                    // parser.parseString(response, (err, result) => {
                    //     this.xml = result;
                    // });
                    this.autocomplete = JSON.parse(response);

                    if (
                        this.autocomplete &&
                        this.autocomplete['autocomplete'] &&
                        this.autocomplete['autocomplete']['term'] &&
                        this.autocomplete['autocomplete']['term'][0] &&
                        Array.isArray(this.autocomplete['autocomplete']['term'])
                    ) {
                        this.heroes = [];

                        for (
                            let i = 0;
                            i <
                            this.autocomplete['autocomplete']['term'].length;
                            i++
                        ) {
                            this.txtstring = this.autocomplete['autocomplete'][
                                'term'
                            ][i].replace(searchValue, '');

                            if (
                                this.autocomplete['autocomplete']['term'][
                                    i
                                ].search(searchValue) == -1
                            ) {
                                searchValue = '';
                            }

                            //      this.txtstring = "- " + this.xml['SPIDEX']['AUTOCOMPLETE'][0]['TERM'][i].replace(re, '<span style="color: #ff7100;" (click)="open($event, item)">' + searchValue + '</span>');

                            this.heroes.push({
                                name: this.txtstring,
                                name2: searchValue,
                                complete:
                                    this.autocomplete['autocomplete']['term'][i]
                            });
                        }
                        if (
                            this.autocomplete['autocomplete']['term'] !=
                            'LET OP: De zoekopdracht mag niet meer dan 10 zoekwoorden bevatten.'
                        ) {
                            this.suggestions.nativeElement.innerHTML =
                                'Bedoelde je...';
                            this.titlesuggestions = true;
                        }
                    } else {
                        this.suggestions.nativeElement.innerHTML = '';
                        this.titlesuggestions = false;
                    }

                    if (this.autocomplete['docs']['id']) {
                        this.heroes2 = [];
                        for (
                            let i = 0;
                            i < this.autocomplete['docs']['id'].length;
                            i++
                        ) {
                            if (i < 5) {
                                const p: HTMLParagraphElement =
                                    this.renderer.createElement('p');

                                this.httpClient
                                    .get(
                                        this.url2 +
                                            this.autocomplete['docs']['id'],
                                        { responseType: 'text' }
                                    )
                                    .subscribe((response) => {
                                        this.obj = JSON.parse(response);

                                        if (i > 0) {
                                            p.innerHTML =
                                                '<div style="clear: both;  border-bottom: #ccc solid 1px; padding-top: 2px;"></div>';
                                        }
                                        // p.innerHTML =
                                        //     p.innerHTML +
                                        //     '<div style="padding: 5px 0px 5px 16px; font-size: 14px;"><a href="' +
                                        //     this.obj[i]['url'] +
                                        //     '" style="color: #000;"><img style="padding: 3px 10px 0px 0px; float: left;" src="' +
                                        //     this.obj[i]['img'] +
                                        //     '" height="60"><strong>' +
                                        //     this.obj[i]['name'] +
                                        //     '</strong><br>' +
                                        //     this.obj[i]['sub'] +
                                        //     '<br><div style="float: right; font-size: 15px; padding-right: 5px;">€ ' +
                                        //     this.obj[i]['prijs'] +
                                        //     '</div></a></div>';

                                        this.heroes2.push({
                                            name: this.obj[i]['name'],
                                            url: this.obj[i]['url'],
                                            img: this.obj[i]['img'],
                                            subtitle: this.obj[i]['sub'],
                                            price: this.obj[i]['prijs']
                                        });
                                    });

                                this.titleproducts = true;

                                // this.renderer.appendChild(
                                //     this.products.nativeElement,
                                //     p
                                // );
                            }
                            //console.log("asd");
                        }
                    } else {
                        this.titleproducts = false;
                    }
                });
        } else {
            this.suggestions.nativeElement.innerHTML = '';
            this.color = 'none';
            //this.products.nativeElement.innerHTML = '';
        }

        //console.log(searchValue);
    }

    public open(item) {
        //document.getElementById("queryField").value = item;
        const inputValue = ((<HTMLInputElement>(
            document.getElementById('queryFieldFooter')
        )).value = item);
        this.queryFooter.nativeElement.value = item;
        this.onSearchChange(item);
    }

    getSafeHTML(value: {}) {
        const json = JSON.stringify(value, null, 2);
        const html = `<script type="application/ld+json">${json}</script>`;
        // Inject to inner html without Angular stripping out content
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    closewindow(): void {
        this.color = 'none';
        this.queryFooter.nativeElement.value = '';
        //this.zoekterm = '';
    }
}
