<div class="mobile-header" style="position: fixed; top: 0; z-index: 99; width: 100%">
    <div class="mobile-header__panel">
        <!-- <div class="container">-->
        <div class="mobile-header__body" style="padding-left: 7px;">
            <button class="mobile-header__menu-button" (click)="menu.open()" aria-label="Menu">
                <app-icon name="menu-18x14" size="18x14"></app-icon>
            </button>
            <a class="mobile-header__logo" routerLink="/">
                <img src="/assets/images/logos/voordeelvloeren.svg" alt="Logo voordeelvloeren" width="160" height="31">
            </a>

            <div class="site-header__search d-block d-sm-none">
                <app-mobile-search></app-mobile-search>
            </div>

            <div class="site-header__search d-none d-sm-block">
                <app-header-search-xs></app-header-search-xs>
            </div>

            <!-- <div class="mobile-header__search" appDropdown="mobile-header__search--opened" #search="appDropdown">
                    <form class="mobile-header__search-form" action="">
                        <input class="mobile-header__search-input" name="search" placeholder="Waar ben je naar op zoek" aria-label="Site search" type="text" autocomplete="off" (keydown.escape)="search.close()" #searchInput>
                        <button class="mobile-header__search-button mobile-header__search-button--submit" type="submit">
                            <app-icon name="search-20" size="20"></app-icon>
                        </button>
                        <button class="mobile-header__search-button mobile-header__search-button--close" type="button" (click)="search.close()">
                            <app-icon name="cross-20" size="20"></app-icon>
                        </button>
                        <div class="mobile-header__search-body"></div>
                    </form>
                </div>

                <div class="mobile-header__indicators">
                    <div class="indicator indicator--mobile-search indicator--mobile d-sm-none">
                        <button class="indicator__button" (click)="search.open(); searchInput.focus()">
                            <span class="indicator__area" style="background: #5e5351;">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </button>
                    </div>-->
            <!--
                    <div class="indicator indicator--mobile d-sm-flex d-none">
                        <a routerLink="/wishlist" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="heart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ wishlist.count$|async }}</span>
                            </span>
                        </a>
                    </div>
-->
            <div class="indicator indicator--mobile">
                <a routerLink="/winkelmandje" class="indicator__button">
                    <span class="indicator__area" style="padding: 0px 17px;">
                        <app-icon name="cart-20" size="20"></app-icon>
                        <span class="indicator__value">{{ cart.quantity$|async }}</span>
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div style="display: block; height: 3px; border-bottom: 1px solid #dee2e6; z-index: 999; background-color: #fff;">
    </div>
</div>



<!-- Hier moet een menu komen -->








<!--

<div class="site-header__nav-panel  d-md-block  d-none">







    <div class="mobile-header">
        <div class="site-header__panel">
            <div class="nav-panel">
            <div class="container">



<ul class="nav-links__list">

    <li class="nav-links__item" style="padding-top: 6px;">
        <a href="#">
        <span>
            Laminaat
        </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item" style="padding-top: 6px;">
        <a href="#">
        <span>
           PVC vloeren
        </span></a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item" style="padding-top: 6px;">
        <a href="#">
        <span>
            Ondervloeren
        </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item" style="padding-top: 6px;">
        <a href="#">
        <span>
            Plinten en profielen
        </span>
        </a>
    </li>

    <div class="nav-links__devider"></div>

    <li class="nav-links__item" style="padding-top: 6px;">
        <a href="#">
        <span>
            Combinatie korting
        </span>
        </a>
    </li>


</ul>
</div>
</div>
</div>
</div>

</div>
-->

<br><br>

<div class="site-header__middle border-bottom" style="height: 53px; padding-top: 17px; ">

    <div class="row" style="margin: auto; width: 100%; ">




        <div style="width: 100%; text-align: center;">
            <div class="create" @slideInOut *ngIf="messageShownIndex == 0">
                <div class="checkmark"
                    style="position: relative; margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/bezorgen-afhalen">Bezorging tot <span
                        class="orangebold">achter</span> de deur</a>
            </div>

            <div class="create" @slideInOut *ngIf="messageShownIndex == 1">
                <div class="checkmark"
                    style="position: relative; margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/retourneren"> <span
                        class="orangebold">Gratis</span> binnen 30 dagen ophaalservice</a>
            </div>

            <div class="create" @slideInOut *ngIf="messageShownIndex == 2">
                <div class="checkmark"
                    style="position: relative;  margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/betaalmogelijkheden">iDeal betaling <span
                        class="orangebold">extra {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}% korting</span></a>
            </div>
        </div>






        <!-- <div id="text-carousel" class="carouselx">
            <div class="text-itemx">
                <div class="checkmark"
                    style="position: relative; top: 4px; margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/bezorgen-afhalen">Bezorging tot <span
                        class="orangebold">achter</span> de deur</a>
            </div>
            <div class="text-itemx">

                <div class="checkmark"
                    style="position: relative; top: 4px; margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/retourneren"> <span
                        class="orangebold">Gratis</span> binnen 30 dagen ophaalservice</a>
            </div>
            <div class="text-itemx">

                <div class="checkmark"
                    style="position: relative; top: 4px; margin-top: 2px; margin-right: 4px; display: inline-table;">
                </div><a class="blacklink" routerLink="/klantenservice/betaalmogelijkheden">iDeal betaling <span
                        class="orangebold">extra 3% korting</span></a>
            </div>
        </div> -->

        <!-- <div>




                <ul class="inline-list" style="width: 100%; position: absolute; top: 55px; overflow: unset !important; text-align: center; left: 0px; max-height: none !important;">

                    <li class="inline-list__item inline-list__item--gap-4 animate"><a class="blacklink"
                        routerLink="/klantenservice/bezorgen-afhalen">
                        <div class="checkmark" style="position: relative; top: 2px; margin-top: 2px; margin-right: 4px; display: inline-table;"></div>
                        Bezorging tot <span class="orangebold">achter</span> de deur
                    </a>
                </li>



                    <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                            routerLink="/klantenservice/betaalmogelijkheden">
                            <div class="checkmark" style="position: relative; top: 2px; margin-top: 2px; margin-right: 4px; display: inline-table;"></div>
                            <span class="orangebold">Gratis</span> binnen 30 dagen ophaalservice
                        </a>
                    </li>

                    <li class="inline-list__item inline-list__item--gap-4"><a class="blacklink"
                        routerLink="/klantenservice/betaalmogelijkheden">
                        <div class="checkmark" style="position: relative; top: 2px; margin-top: 2px; margin-right: 4px; display: inline-table;"></div>
                        iDeal betaling <span class="orangebold">extra 3% korting</span>
                    </a>
                </li>


                </ul>

        </div> -->

    </div>

    <div class="indicator" style="float: right;">
        <a [routerLink]="'/verlanglijstje'" class="indicator__button">
            <span class="indicator__area3">
                <app-icon name="heart-20" size="20"></app-icon>
                <span class="indicator__value">{{wishlist.quantity$|async}}</span>
            </span>
        </a>
    </div>

</div>
