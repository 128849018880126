<div class="nav-panel">
    <div class="nav-panel__container container">
        <div class="nav-panel__row">
            <div *ngIf="logo" class="nav-panel__logo">
                <a [routerLink]="root.home()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="120px" height="20px">
                        <path d="M118.5,20h-1.1c-0.6,0-1.2-0.4-1.4-1l-1.5-4h-6.1l-1.5,4c-0.2,0.6-0.8,1-1.4,1h-1.1c-1,0-1.8-1-1.4-2l1.1-3
                                 l1.5-4l3.6-10c0.2-0.6,0.8-1,1.4-1h1.6c0.6,0,1.2,0.4,1.4,1l3.6,10l1.5,4l1.1,3C120.3,19,119.5,20,118.5,20z M111.5,6.6l-1.6,4.4
                                 h3.2L111.5,6.6z M99.5,20h-1.4c-0.4,0-0.7-0.2-0.9-0.5L94,14l-2,3.5v1c0,0.8-0.7,1.5-1.5,1.5h-1c-0.8,0-1.5-0.7-1.5-1.5v-17
                                 C88,0.7,88.7,0,89.5,0h1C91.3,0,92,0.7,92,1.5v8L94,6l3.2-5.5C97.4,0.2,97.7,0,98.1,0h1.4c1.2,0,1.9,1.3,1.3,2.3L96.3,10l4.5,7.8
                                 C101.4,18.8,100.7,20,99.5,20z M80.3,11.8L80,12.3v6.2c0,0.8-0.7,1.5-1.5,1.5h-1c-0.8,0-1.5-0.7-1.5-1.5v-6.2l-0.3-0.5l-5.5-9.5
                                 c-0.6-1,0.2-2.3,1.3-2.3h1.4c0.4,0,0.7,0.2,0.9,0.5L76,4.3l2,3.5l2-3.5l2.2-3.8C82.4,0.2,82.7,0,83.1,0h1.4c1.2,0,1.9,1.3,1.3,2.3
                                 L80.3,11.8z M60,20c-5.5,0-10-4.5-10-10S54.5,0,60,0s10,4.5,10,10S65.5,20,60,20z M60,4c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6
                                 S63.3,4,60,4z M47.8,17.8c0.6,1-0.2,2.3-1.3,2.3h-2L41,14h-4v4.5c0,0.8-0.7,1.5-1.5,1.5h-1c-0.8,0-1.5-0.7-1.5-1.5v-17
                                 C33,0.7,33.7,0,34.5,0H41c0.3,0,0.7,0,1,0.1c3.4,0.5,6,3.4,6,6.9c0,2.4-1.2,4.5-3.1,5.8L47.8,17.8z M42,4.2C41.7,4.1,41.3,4,41,4h-3
                                 c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h3c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.9-0.5C43.6,8.8,44,7.9,44,7C44,5.7,43.2,4.6,42,4.2z
                                  M29.5,4H25v14.5c0,0.8-0.7,1.5-1.5,1.5h-1c-0.8,0-1.5-0.7-1.5-1.5V4h-4.5C15.7,4,15,3.3,15,2.5v-1C15,0.7,15.7,0,16.5,0h13
                                 C30.3,0,31,0.7,31,1.5v1C31,3.3,30.3,4,29.5,4z M6.5,20c-2.8,0-5.5-1.7-6.4-4c-0.4-1,0.3-2,1.3-2h1c0.5,0,0.9,0.3,1.2,0.7
                                 c0.2,0.3,0.4,0.6,0.8,0.8C4.9,15.8,5.8,16,6.5,16c1.5,0,2.8-0.9,2.8-2c0-0.7-0.5-1.3-1.2-1.6C7.4,12,7,11,7.4,10.3l0.4-0.9
                                 c0.4-0.7,1.2-1,1.8-0.6c0.6,0.3,1.2,0.7,1.6,1.2c1,1.1,1.7,2.5,1.7,4C13,17.3,10.1,20,6.5,20z M11.6,6h-1c-0.5,0-0.9-0.3-1.2-0.7
                                 C9.2,4.9,8.9,4.7,8.6,4.5C8.1,4.2,7.2,4,6.5,4C5,4,3.7,4.9,3.7,6c0,0.7,0.5,1.3,1.2,1.6C5.6,8,6,9,5.6,9.7l-0.4,0.9
                                 c-0.4,0.7-1.2,1-1.8,0.6c-0.6-0.3-1.2-0.7-1.6-1.2C0.6,8.9,0,7.5,0,6c0-3.3,2.9-6,6.5-6c2.8,0,5.5,1.7,6.4,4C13.3,4.9,12.6,6,11.6,6
                                 z"></path>
                    </svg>
                </a>
            </div>



            <!-- PVC MENU -->

            <div class="nav-panel__nav-links nav-links"
                style="margin-right: 0px; margin-left: 0px; z-index: 9999; padding-left: 0px">

                <ul class="nav-links__list" style="width: 100%">
                    <li *ngFor="let item of items2" class="nav-handigomteweten__item" style="float: right" [ngClass]="{
                      'nav-links__item--with-submenu': item.menu,
                      'nav-links__item--hover': hoveredItem === item
                  }" (mouseenter)="onItemMouseEnter(item, $event)" (mouseleave)="onItemMouseLeave(item)">

                        <ng-template #link2>

                    <li class="nav-links__item" style="padding: 0px 14px 0px 0px;">

                        <a routerLink="/pvc-vloeren" (click)="onSubItemClick()">
                            PVC vloeren
                        </a>

                    </li>


                    </ng-template>


                    <ng-container [ngTemplateOutlet]="link2"></ng-container>

                    <!-- <a *ngIf="item.external" [href]="item.url" [target]="item.target">
                      <ng-container [ngTemplateOutlet]="link"></ng-container>
                  </a>  -->

                    <!-- <div *ngIf="item.menu && item.menu.type == 'menu'" class="nav-links__menu">
                      <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
                  </div> -->



                    <div class="nav-links__megamenu nav-links__megamenu--size--xl">


                        <div class="megamenuhandig">
                            <div class="row">
                                <div class="col-12">


                                    <table style="max-width: 1300px; width: 100%;" cellspacing="0" cellpadding="5">
                                        <tbody>
                                            <tr>
                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px; padding-bottom: 10px;">
                                                    <span class="falldownmenuheader">PVC merken</span><br>

                                                    <a routerLink="/pvc-vloeren/ambiant" (click)="onSubItemClick()"
                                                        class="black">Ambiant</a><br>
                                                    <a routerLink="/pvc-vloeren/aspecta" (click)="onSubItemClick()"
                                                        class="black">Aspecta</a><br>
                                                    <a routerLink="/pvc-vloeren/coretec" (click)="onSubItemClick()"
                                                        class="black">COREtec </a><br>
                                                    <a routerLink="/pvc-vloeren/douwes-dekker"
                                                        (click)="onSubItemClick()" class="black">Douwes
                                                        Dekker</a><br>
                                                    <a routerLink="/pvc-vloeren/floer"
                                                        (click)="onSubItemClick()" class="black">FLOER</a><br>
                                                    <a routerLink="/pvc-vloeren/floorify"
                                                        (click)="onSubItemClick()" class="black">Floorify</a><br>
                                                    <a routerLink="/pvc-vloeren/meisterdesign"
                                                        (click)="onSubItemClick()" class="black">MeisterDesign</a><br>
                                                    <a routerLink="/pvc-vloeren/quickstep-alpha"
                                                        (click)="onSubItemClick()" class="black">QuickStep Alpha</a><br>
                                                    <!-- <a routerLink="/pvc-vloeren/quickstep-flex"
                                                        (click)="onSubItemClick()" class="black">
                                                        QuickStep Flex</a><br><br> -->


                                                </td>
                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Soorten PVC</span><br>

                                                    <a href="/pvc-vloeren/visgraat" class="black">Visgraat</a><br>
                                                    <a href="/hongaarse-punt" class="black">Hongaarse punt</a><br>
                                                    <a href="/pvc-vloeren/tegel" class="black">Tegel</a><br>
                                                    <a href="/pvc-vloeren/plank-standaard" class="black">Plank
                                                        standaard</a><br>
                                                    <a href="/pvc-vloeren/plank-lang" class="black">Plank lang</a><br>
                                                    <a href="/pvc-vloeren/plank-langbreed" class="black">Plank lang en
                                                        breed</a><br>

                                                    <a href="/pvc-vloeren/plank-breed" class="black">Plank breed</a>

                                                </td>
                                                <td id="footer"
                                                    style="width: 29%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Handige informatie</span><br>
                                                    <a routerLink="/handige-informatie/pvc-of-laminaat-vloer"
                                                        (click)="onSubItemClick()" class="black">Wat is het verschil
                                                        tussen PVC en laminaat?</a><br>

                                                    <a routerLink="/handige-informatie/complete-vloer"
                                                        (click)="onSubItemClick()" class="black">Zo bestel je een
                                                        complete vloer</a>
                                                    <br> <a routerLink="/combinatie-korting" (click)="onSubItemClick()"
                                                        class="black">Combinatie korting</a>
                                                    <br> <a routerLink="/klantenservice/showroom"
                                                        (click)="onSubItemClick()" class="black">Kom kijken in onze
                                                        showroom</a>
                                                    <br> <a routerLink="/faq/onderwerp/top-10"
                                                        (click)="onSubItemClick()" class="black">Veel gestelde
                                                        vragen</a>

                                                    <br>
                                                    <br>

                                                    <div id="stars" style=" float: left;">


                                                        <div class="btn btn-sm"
                                                            style=" color: #fff; background-image: url(/assets/images/icons/arrow-white-right.svg);
                                                              background-position: 132px 8px; padding-right: 30px; background-size: 9px; background-repeat: no-repeat; background-color: #ff7100; border-radius: 7px;">

                                                            <a routerLink="/pvc-vloeren" style="color: #fff;"
                                                                (click)="onSubItemClick()">Alle
                                                                PVC vloeren</a>
                                                        </div>


                                                    </div>

                                                </td>

                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-bottom: 0px; background-image: url('/assets/images/global/bigmenu-pvc.webp'); background-repeat: no-repeat; border-left: 1px solid #ff7100; border-right: 1px solid #ff7100;">


                                                    <div
                                                        style="padding-top: 180px; text-align: center; padding-bottom: 11px; ">

                                                        <a style="color: #fff; font-size: 16px; font-weight: bold;"
                                                            href="/pvc-vloeren/coretec" (click)="onSubItemClick()">De
                                                            best
                                                            verkochte<br>watervaste klikvloer ter wereld</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>



                    </div>
                    </li>
                </ul>

            </div>
            <div style="width: 2px; background-color: #5e5351; height: 20px; margin-right: 10px;"></div>
            <!-- LAMINAAT MENU -->

            <div class="nav-panel__nav-links nav-links"
                style="margin-right: 0px; margin-left: 0px; z-index: 9999; padding-left: 0px">

                <ul class="nav-links__list" style="width: 100%">
                    <li *ngFor="let item of items" class="nav-handigomteweten__item" style="float: right" [ngClass]="{
                         'nav-links__item--with-submenu': item.menu,
                         'nav-links__item--hover': hoveredItem === item
                     }" (mouseenter)="onItemMouseEnter(item, $event)" (mouseleave)="onItemMouseLeave(item)">

                        <ng-template #link1>

                    <li class="nav-links__item" style="padding: 0px 14px 0px 4px">

                        <a routerLink="/laminaat" (click)="onSubItemClick()">
                            Laminaat
                        </a>

                    </li>


                    </ng-template>

                    <!-- <a *ngIf="!item.external" [routerLink]="item.url" [target]="item.target">
                        <ng-container [ngTemplateOutlet]="link1"></ng-container>
                    </a> -->


                    <ng-container [ngTemplateOutlet]="link1"></ng-container>




                    <div *ngIf="item.menu && item.menu.type == 'menu'" class="nav-links__menu">
                        <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
                    </div>



                    <div *ngIf="item.menu && item.menu.type == 'megamenu'"
                        class="nav-links__megamenu nav-links__megamenu--size--xl">


                        <div class="megamenuhandig">
                            <div class="row">
                                <div class="col-12">


                                    <table style="max-width: 1300px; width: 100%;" cellspacing="0" cellpadding="5">
                                        <tbody>
                                            <tr>
                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px; padding-bottom: 10px;">
                                                    <span class="falldownmenuheader">Laminaat merken</span><br>

                                                    <a routerLink="/laminaat/ambiant" (click)="onSubItemClick()"
                                                        class="black">Ambiant</a><br>

                                                    <a routerLink="/laminaat/beautifloor" (click)="onSubItemClick()"
                                                        class="black">Beautifloor </a><br>
                                                    <a routerLink="/laminaat/douwes-dekker" (click)="onSubItemClick()"
                                                        class="black">Douwes Dekker</a><br>
                                                    <a routerLink="/laminaat/floer"
                                                        (click)="onSubItemClick()" class="black">FLOER</a><br>
                                                    <a routerLink="/laminaat/joka" (click)="onSubItemClick()"
                                                        class="black">Joka</a><br>
                                                    <a routerLink="/laminaat/lifestyle-interior"
                                                        (click)="onSubItemClick()" class="black">Lifestyle
                                                        Interior</a><br>

                                                    <a routerLink="/laminaat/meister" (click)="onSubItemClick()"
                                                        class="black">Meister</a><br>
                                                    <a routerLink="/laminaat/quickstep" (click)="onSubItemClick()"
                                                        class="black">QuickStep</a><br>
                                                    <a routerLink="/laminaat/treefloor" (click)="onSubItemClick()"
                                                        class="black">
                                                        Treefloor</a>
                                                    <!-- <a routerLink="/laminaat/vitality" (click)="onSubItemClick()"
                                                         class="black">Vitality</a>
                                                     <br>--><br>

                                                </td>

                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Soorten laminaat</span><br>

                                                    <!-- <a routerLink="/laminaat" [queryParams]="{ sort : 'prijs_asc', filter_model : 'plank-standaard'}" (click)="onSubItemClick()"
                                                     class="black">Plank standaard</a><br> -->
                                                    <a href="/laminaat/visgraat" class="black">Visgraat</a><br>
                                                    <a href="/laminaat/tegellaminaat" class="black">Tegel
                                                        laminaat</a><br>
                                                    <a href="/laminaat/waterbestendig" class="black">Waterbestendig
                                                        laminaat</a><br>

                                                    <a href="/laminaat/plank-breed" class="black">Plank breed</a><br>
                                                    <a href="/laminaat/plank-lang" class="black">Plank lang</a><br>
                                                    <a href="laminaat/plank-langbreed" class="black">Plank lang en
                                                        breed</a>




                                                    <br>
                                                    <br>


                                                    <div id="stars" style=" float: left;">


                                                        <div class="btn btn-sm"
                                                            style=" color: #fff; background-image: url(/assets/images/icons/arrow-white-right.svg);
                                                                 background-position: 152px 8px; padding-right: 30px; background-size: 9px; background-repeat: no-repeat; background-color: #ff7100; border-radius: 7px;">

                                                            <a routerLink="/laminaat" style="color: #fff;"
                                                                (click)="onSubItemClick()">Alle
                                                                laminaatvloeren</a>
                                                        </div>


                                                    </div>






                                                </td>
                                                <td id="footer"
                                                    style="width: 29%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Handige informatie</span><br>
                                                    <a routerLink="/handige-informatie/pvc-of-laminaat-vloer"
                                                        (click)="onSubItemClick()" class="black">Wat is het verschil tussen PVC en laminaat?</a><br>
                                                    <a routerLink="/handige-informatie/welke-ondervloer"
                                                        (click)="onSubItemClick()" class="black">Welke ondervloer is
                                                        de beste?</a><br>
                                                    <a routerLink="/handige-informatie/complete-vloer"
                                                        (click)="onSubItemClick()" class="black">Zo bestel je een
                                                        complete vloer</a>
                                                    <br> <a routerLink="/combinatie-korting" (click)="onSubItemClick()"
                                                        class="black">Combinatie korting</a>
                                                    <br> <a routerLink="/klantenservice/showroom"
                                                        (click)="onSubItemClick()" class="black">Kom kijken in onze
                                                        showroom
                                                    </a>
                                                    <br> <a routerLink="/faq/onderwerp/top-10"
                                                        (click)="onSubItemClick()" class="black">Veel gestelde
                                                        vragen</a>

                                                    <br>
                                                    <br>

                                                    <div id="stars" style=" float: left;">


                                                        <div class="btn btn-sm"
                                                            style=" color: #fff; background-image: url(/assets/images/icons/arrow-white-right.svg);
                                                                 background-position: 144px 8px; padding-right: 30px; background-size: 9px; background-repeat: no-repeat; background-color: #ff7100; border-radius: 7px;">

                                                            <a routerLink="/combinatie-korting" style="color: #fff;"
                                                                (click)="onSubItemClick()">Combinatie
                                                                korting</a>
                                                        </div>


                                                    </div>

                                                </td>

                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-bottom: 10px; background-image: url('/assets/images/global/bigmenu-laminaat.webp'); background-repeat: no-repeat; border-left: 1px solid #ff7100; border-right: 1px solid #ff7100;">


                                                    <div style="padding-left: 16px; padding-top: 205px; ">
                                                        <span
                                                            style=" padding: 5px 10px 0px 0px; fill: #fff; float: left;"><svg
                                                                width="9px" viewBox="0 0 63 99" aria-hidden="true"
                                                                focusable="false">
                                                                <path _ngcontent-serverApp-c124=""
                                                                    d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                                                                </path>
                                                            </svg></span>
                                                        <a style="color: #fff; font-size: 16px; font-weight: bold;"
                                                            href="/laminaat/visgraat"
                                                            (click)="onSubItemClick()">Visgraat
                                                            laminaatvloeren</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>



                    </div>
                    </li>
                </ul>



            </div>


            <div style="width: 2px; background-color: #5e5351; height: 20px; margin-right: 10px;"></div>
            <!-- ONDERVLOEREN MENU -->

            <div class="nav-panel__nav-links nav-links"
                style="margin-right: 0px; margin-left: 0px; z-index: 9999; padding-left: 0px">

                <ul class="nav-links__list" style="width: 100%">
                    <li *ngFor="let item of items3" class="nav-handigomteweten__item" style="float: right" [ngClass]="{
                   'nav-links__item--with-submenu': item.menu,
                   'nav-links__item--hover': hoveredItem === item
               }" (mouseenter)="onItemMouseEnter(item, $event)" (mouseleave)="onItemMouseLeave(item)">

                        <ng-template #link3>

                    <li class="nav-links__item">
                        <a routerLink="/ondervloeren">
                            <span style="padding-right: 14px; padding-left: 0px;">
                                Ondervloeren
                            </span>
                        </a>
                    </li>


                    </ng-template>

                    <a routerLink="/ondervloeren" (click)="onSubItemClick()">
                        <ng-container [ngTemplateOutlet]="link3"></ng-container>
                    </a>


                    <div class="nav-links__megamenu nav-links__megamenu--size--xl">


                        <div class="megamenuhandig">
                            <div class="row">
                                <div class="col-12">


                                    <table style="max-width: 1300px; width: 100%;" cellspacing="0" cellpadding="5">
                                        <tbody>
                                            <tr>
                                                <td id="footer"
                                                    style="width: 29%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Handige informatie</span><br>

                                                    <a routerLink="/handige-informatie/ondervloerenwijzer"
                                                        (click)="onSubItemClick()"
                                                        class="black">Ondervloerenwijzer</a><br>

                                                    <a routerLink="/handige-informatie/welke-ondervloer"
                                                        (click)="onSubItemClick()" class="black">Waarom heb je een
                                                        ondervloer nodig?</a><br>
                                                    <a routerLink="/handige-informatie/pvc-of-laminaat-vloer"
                                                        (click)="onSubItemClick()" class="black">Wat is het verschil
                                                        tussen PVC en laminaat?</a><br>
                                                    <a routerLink="/handige-informatie/complete-vloer"
                                                        (click)="onSubItemClick()" class="black">Zo bestel je een
                                                        complete vloer</a>
                                                    <br> <a routerLink="/combinatie-korting" (click)="onSubItemClick()"
                                                        class="black">Combinatie korting</a>
                                                    <br> <a routerLink="/klantenservice/showroom"
                                                        (click)="onSubItemClick()" class="black">Kom kijken in onze
                                                        showroom</a>
                                                    <br> <a routerLink="/faq/onderwerp/top-10"
                                                        (click)="onSubItemClick()" class="black">Veel gestelde
                                                        vragen</a>



                                                </td>
                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Soorten Ondervloer</span><br>
                                                    <a routerLink="/ondervloeren/vloerverwarming" class="black"
                                                        (click)="onSubItemClick()">Vloerverwarming</a><br>
                                                    <a routerLink="/ondervloeren/appartement" class="black"
                                                        (click)="onSubItemClick()">Appartement
                                                        10dB</a><br>
                                                    <a routerLink="/ondervloeren/beton" class="black"
                                                        (click)="onSubItemClick()">Beton</a><br>
                                                    <a routerLink="/ondervloeren/hout" class="black"
                                                        (click)="onSubItemClick()">Hout</a><br>
                                                    <a routerLink="/ondervloeren/laminaat" class="black"
                                                        (click)="onSubItemClick()">Laminaat</a><br>
                                                    <a routerLink="/ondervloeren/rubber" class="black"
                                                        (click)="onSubItemClick()">Rubber</a><br>
                                                    <a routerLink="/ondervloeren/pvc-klik-vloeren" class="black"
                                                        (click)="onSubItemClick()">PVC
                                                        klik
                                                        vloeren</a><br>
                                                    <a routerLink="/ondervloeren/accessoires" class="black"
                                                        (click)="onSubItemClick()">Accessoires</a>





                                                </td>

                                                <td id="footer"
                                                    style="width: 23%; vertical-align: top; padding-top: 18px; padding-bottom: 10px;">
                                                    <span class="falldownmenuheader">Ondervloeren merken</span><br>

                                                    <a routerLink="/product/267/aluplus-15m2" (click)="onSubItemClick()"
                                                        class="black">AluPlus</a><br>
                                                    <!-- <a routerLink="/ondervloeren/blue-floor" (click)="onSubItemClick()"
                                                        class="black">Blue Floor 10dB</a><br> -->
                                                    <a routerLink="/ondervloeren/groene-vloerplaat"
                                                        (click)="onSubItemClick()" class="black">Groene Vloerplaat
                                                    </a><br>
                                                    <a routerLink="/product/269/heatfoil-15-m2-10db"
                                                        (click)="onSubItemClick()" class="black">HeatFoil 10dB</a><br>
                                                    <a routerLink="/ondervloeren/quickstep" (click)="onSubItemClick()"
                                                        class="black">QuickStep</a><br>
                                                    <!-- <a routerLink="/ondervloeren/redupax" (click)="onSubItemClick()"
                                                        class="black">Redupax 10dB</a><br> -->
                                                    <a routerLink="/product/279/timbersound-10-m2"
                                                        (click)="onSubItemClick()" class="black">TimberSound</a><br>

                                                    <a routerLink="/ondervloeren" class="black"
                                                        (click)="onSubItemClick()">Alle ondervloeren</a><br><br>



                                                    <div id="stars" style=" float: left;">


                                                        <div class="btn btn-sm"
                                                            style=" color: #fff; background-image: url(/assets/images/icons/arrow-white-right.svg);
                                                           background-position: 145px 8px; padding-right: 30px; background-size: 9px; background-repeat: no-repeat; background-color: #009600; border-radius: 7px;">

                                                            <a routerLink="/handige-informatie/ondervloerenwijzer"
                                                                style="color: #fff;"
                                                                (click)="onSubItemClick()">Ondervloerenwijzer</a>
                                                        </div>


                                                    </div>
                                                    <br>
                                                    <br>

                                                </td>



                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-bottom: 00px; background-image: url('/assets/images/global/handigomteweten-ondervloer-bg.webp'); background-repeat: no-repeat; background-position: right top;   ">


                                                    <div
                                                        style="margin-top: 175px; float: right; width: 230px; padding-bottom: 19px; padding-left: 0px;  margin-right: 20px;">

                                                        <span style="font-size: 18px;  margin-bottom: 0;
                                                        margin-top: 0;
                                                        line-height: 1.2;
                                                        font-weight: 700; padding-left: 10px; color: #305696;">
                                                            ONDERVLOERENWIJZER</span><br>
                                                        <div style="padding-left: 10px;  ">
                                                            <span
                                                                style=" padding: 4px 10px 0px 0px; fill: #0090e3; float: left;"><svg
                                                                    width="9px" viewBox="0 0 63 99" aria-hidden="true"
                                                                    focusable="false">
                                                                    <path _ngcontent-serverApp-c124=""
                                                                        d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                                                                    </path>
                                                                </svg></span>
                                                            <a href="/handige-informatie/welke-ondervloer">Vind de beste
                                                                ondervloer</a>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>



                    </div>
                    </li>
                </ul>



            </div>
            <div style="width: 2px; background-color: #5e5351; height: 20px; margin-right: 10px;"></div>
            <!-- PLINTEN MENU -->

            <div class="nav-panel__nav-links nav-links"
                style="margin-right: 0px; margin-left: 0px; z-index: 9999; padding-left: 0px">

                <ul class="nav-links__list" style="width: 100%">
                    <li *ngFor="let item of items4" class="nav-handigomteweten__item" style="float: right" [ngClass]="{
                 'nav-links__item--with-submenu': item.menu,
                 'nav-links__item--hover': hoveredItem === item
             }" (mouseenter)="onItemMouseEnter(item, $event)" (mouseleave)="onItemMouseLeave(item)">

                        <ng-template #link4>

                    <li class="nav-links__item">
                        <a routerLink="/plinten" (click)="onSubItemClick()">
                            <span style="padding-right: 14px; padding-left: 0px;">
                                Plinten en profielen
                            </span>
                        </a>
                    </li>


                    </ng-template>

                    <a routerLink="/plinten">
                        <ng-container [ngTemplateOutlet]="link4"></ng-container>
                    </a>


                    <div class="nav-links__megamenu nav-links__megamenu--size--xl">


                        <div class="megamenuhandig">
                            <div class="row">
                                <div class="col-12">


                                    <table style="max-width: 1300px; width: 100%;" cellspacing="0" cellpadding="5">
                                        <tbody>
                                            <tr>
                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-top: 18px; padding-bottom: 10px;">

                                                    <span class="falldownmenuheader">Soorten plinten</span><br>
                                                    <a routerLink="/product/573/plakplint-universeel-240-cm"
                                                        class="black" (click)="onSubItemClick()">Plakplinten</a><br>
                                                    <a routerLink="/product/1666/rechte-folieplint-70-x-14-mm-universeel"
                                                        class="black" (click)="onSubItemClick()">Muurplinten</a><br>
                                                    <a routerLink="/plinten/mdf-plinten-afgelakt" class="black"
                                                        (click)="onSubItemClick()">MDF plint
                                                        afgelakt</a><br>
                                                    <a routerLink="/plinten/mdf-plinten-gegrond" class="black"
                                                        (click)="onSubItemClick()">MDF plint
                                                        gegrond</a><br>

                                                    <br>
                                                    <span class="falldownmenuheader">Hoekprofielen</span><br>

                                                    <a routerLink="/plinten/hoekprofielen" class="black"
                                                        (click)="onSubItemClick()">Hoekprofiel</a><br>
                                                    <a routerLink="/product/1669/hoeklijnprofiel-10-x-25-mm-zelfklevend-universeel-200-cm"
                                                        class="black" (click)="onSubItemClick()">
                                                        Hoekprofiel met houtnerf </a><br>

                                                </td>
                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-top: 18px;">


                                                    <span class="falldownmenuheader">Plinten merken</span><br>

                                                    <a routerLink="/plinten/quickstep"
                                                        (click)="onSubItemClick()" class="black">QuickStep plinten en
                                                        montage</a><br>
                                                    <a routerLink="/plinten/meister-plinten" (click)="onSubItemClick()"
                                                        class="black">Meister plinten</a><br>

                                                    <a routerLink="/product/1666/rechte-folieplint-70-x-14-mm-universeel"
                                                        (click)="onSubItemClick()" class="black">PPC + 175
                                                        decoren</a><br><br>
                                                    <span class="falldownmenuheader">QuickStep accessoires</span><br>

                                                    <a routerLink="/plinten/quickstep/montage"
                                                        (click)="onSubItemClick()" class="black"> Montage</a><br>
                                                    <a routerLink="/plinten/quickstep/onderhoud"
                                                        (click)="onSubItemClick()" class="black">Onderhoud</a><br>
                                                    <a routerLink="/plinten/quickstep/incizo-profiel-laminaat"
                                                        (click)="onSubItemClick()" class="black">Incizo
                                                        profielen</a><br>
                                                    <div style="height: 20px;">&nbsp;</div>







                                                </td>
                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-top: 18px;">
                                                    <span class="falldownmenuheader">Handige informatie</span><br>
                                                    <a routerLink="/handige-informatie/mooie-plinten"
                                                        (click)="onSubItemClick()" class="black">Welke plint past het
                                                        beste bij jouw vloer?
                                                    </a><br>
                                                    <a routerLink="/handige-informatie/welke-ondervloer"
                                                        (click)="onSubItemClick()" class="black">Waarom heb je een
                                                        ondervloer nodig?</a><br>
                                                    <a routerLink="/handige-informatie/complete-vloer"
                                                        (click)="onSubItemClick()" class="black">Zo bestel je een
                                                        complete vloer</a>
                                                    <br> <a routerLink="/combinatie-korting" (click)="onSubItemClick()"
                                                        class="black">Combinatie korting</a>
                                                    <br> <a routerLink="/klantenservice/showroom"
                                                        (click)="onSubItemClick()" class="black">Kom kijken in onze
                                                        showroom</a>
                                                    <br> <a routerLink="/faq/onderwerp/top-10"
                                                        (click)="onSubItemClick()" class="black">Veel gestelde
                                                        vragen</a>

                                                    <br>
                                                    <br>

                                                    <div id="stars" style=" float: left;">


                                                        <div class="btn btn-sm"
                                                            style=" color: #fff; background-image: url(/assets/images/icons/arrow-white-right.svg);
                                                         background-position: 177px 8px; padding-right: 30px; background-size: 9px; background-repeat: no-repeat; background-color: #ff7100; border-radius: 7px;">

                                                            <a routerLink="/plinten" style="color: #fff;"
                                                                (click)="onSubItemClick()">Alle
                                                                plinten en profielen</a>
                                                        </div>


                                                    </div>

                                                </td>

                                                <td id="footer"
                                                    style="width: 25%; vertical-align: top; padding-bottom: 10px; background-image: url('/assets/images/global/bigmenu-ondervloeren.webp'); background-repeat: no-repeat; border-left: 1px solid #ff7100; border-right: 1px solid #ff7100; background-position: right bottom;">
                                                    <div style="padding-left: 16px; padding-top: 205px; ">
                                                        <span
                                                            style=" padding: 5px 10px 0px 0px; fill: #fff; float: left;"><svg
                                                                width="9px" viewBox="0 0 63 99" aria-hidden="true"
                                                                focusable="false">
                                                                <path _ngcontent-serverApp-c124=""
                                                                    d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                                                                </path>
                                                            </svg></span>
                                                        <a style="color: #fff; font-size: 16px; font-weight: bold;"
                                                            routerLink="/plinten/universeel"
                                                            (click)="onSubItemClick()">Meer dan 175 decoren
                                                            muurplint</a>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>



                    </div>
                    </li>
                </ul>



            </div>

             <!-- Wandpanelen  -->
            <div style="width: 2px; background-color: #5e5351; height: 20px;"></div>

            <ul class="nav-links__list">
                <li class="nav-links__item">
                    <a [routerLink]="'/wandpanelen'">
                        <span>
                            Wandpanelen
                        </span>
                    </a>
                </li>
            </ul>
            <!-- asdasd -->



            <!-- <ul class="nav-links__list"> -->

            <!-- <li class="nav-links__item">
                    <a [routerLink]="'/laminaat'">
                        <span>
                            Laminaat
                        </span>
                    </a>
                </li> -->













            <!-- <div class="nav-links__devider"></div>

                <li class="nav-links__item">
                    <a [routerLink]="'/combinatie-korting'">
                        <span>
                            Combinatie korting
                        </span>
                    </a>
                </li>


            </ul> -->



            <!-- .nav-links / end -->

            <div class="nav-panel__indicators">
                <div *ngIf="search" class="indicator indicator--trigger--click" appDropdown="indicator--opened"
                    #search="appDropdown">
                    <button type="button" class="indicator__button"
                        (click)="$event.preventDefault(); search.toggle(); search.isOpen ? searchField.focus() : '';"
                        aria-label="Zoeken">
                        <span class="indicator__area">
                            <app-icon class="indicator__icon" name="search-20" size="20"></app-icon>
                            <app-icon class="indicator__icon indicator__icon--open" name="cross-20" size="20">
                            </app-icon>
                        </span>
                    </button>

                    <div class="indicator__dropdown">
                        <div class="drop-search">
                            <form action="" class="drop-search__form">
                                <input class="drop-search__input" name="search" (keydown.escape)="search.close()"
                                    placeholder="Search over 10,000 products" aria-label="Site search" type="text"
                                    autocomplete="off" #searchField>
                                <button class="drop-search__button drop-search__button--submit" type="submit"
                                    aria-label="Zoeken">
                                    <app-icon name="search-20" size="20"></app-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>




            </div>
            <ul class="nav-links__list">
                <li class="nav-links__item">
                    <a [routerLink]="'/klantenservice/legservice'">
                        <span>
                            Legservice
                        </span>
                    </a>
                </li>
            </ul>
            <div style="width: 2px; background-color: #5e5351; height: 20px; margin-right: 3px;"></div>
            <ul class="nav-links__list">
                <li class="nav-links__item">
                    <a [routerLink]="'/combinatie-korting'">
                        <span>
                            Combinatie korting
                        </span>
                    </a>
                </li>
            </ul>
            <div style="width: 2px; background-color: #5e5351; height: 20px; margin-right: 10px;"></div>
            <app-header-handigomteweten class="nav-panel__nav-links nav-links"
                style="margin-right: 15px; z-index: 9999;"></app-header-handigomteweten>





        </div>
    </div>
</div>

<div id="whitescreen"
    style="position: relative; padding: 0px; margin: 0px; top: 0px; left: 0px; width: 100%;  background-color: #fff; display: none; z-index: 999;  ">
</div>
<!-- <div id="whitescreen"
    style="position: relative; padding: 0px; margin: 0px; top: 0px; left: 0px; width: 100%; height: 280px; background-color: #fff; display: none; z-index: 999;  ">
</div> -->
<div id="blackscreen" class="fadehide"
    style="position: fixed; padding: 0px; margin: 0px; top: 352px; left: 0px; width: 100%; height: 100%; background-color: #000; z-index: 1; border-top: #C0C0C0 1px solid;  ">
</div>


<!-- <div
    style="background-color: #fff; height: 275px;  width: 100%; position: fixed; top: 100px; z-index: 1000; border-bottom: solid 1px #5e5351; display: none; ">
    <div style="margin: auto; width: 1240px; ">


        <table width="1300" cellspacing="0" cellpadding="5" border="0" align="center">
            <tbody>
                <tr>
                    <td id="footer" style="vertical-align: top; padding-top: 20px;">
                        <h3>Handig om te weten5</h3>
                        <a href="/welke-ondervloer.php" class="black">Welke ondervloer is de beste?</a><br>
                        <a href="/mooie-plinten.php" class="black">Muurplint of plakplint? </a><br>
                        <a href="/complete-vloer.php" class="black">Zo bestel je een complete vloer</a><br>
                        <a href="/over.php" class="black">Over Voordeelvloeren.nl</a><br>
                        <br>
                        <div style="padding-top: 10px;"></div>
                        <h3>Klantenreacties</h3>

                        <a href="https://www.feedbackcompany.com/nl-nl/reviews//voordeellaminaatvloeren-nl"
                            target="_blank" rel="nofollow" class="black">
                            <div id="stars" style=" float: left; width: 100px;">

                                <i class="fa fa-star" aria-hidden="true" style="color: #009600;"></i>
                                <i class="fa fa-star" aria-hidden="true" style="color: #009600;"></i>
                                <i class="fa fa-star" aria-hidden="true" style="color: #009600;"></i>
                                <i class="fa fa-star" aria-hidden="true" style="color: #009600;"></i>
                                <i class="fa fa-star" aria-hidden="true" style="color: #009600;"></i>


                            </div>
                        </a>


                        <div style="display: block; float: left;">

                            <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeellaminaatvloeren-nl"
                                target="_blank" rel="nofollow" class="black">


                                <strong>9.5</strong>/<strong itemprop="best">10</strong> van <strong>420</strong>
                                beoordelingen</a>

                        </div>


                    </td>
                    <td id="footer" style="vertical-align: top; padding-top: 20px;">
                        <h3>Ons team staat klaar om te helpen</h3>
                        <a href="/contact.php" class="black">Contact</a><br>
                        <a href="/bezorgen.php" class="black">Bezorgen of afhalen</a><br>
                        <a href="/retourneren.php" class="black">Retourneren</a><br>
                        <a href="/betaalmogelijkheden.php" class="black">Betaalmogelijkheden</a><br>
                        <a href="/faq/" class="black">Veel gestelde vragen</a><br>
                        <a href="/service.php" class="black">Service</a>
                    </td>
                    <td id="footer" style="vertical-align: top; padding-top: 20px;">
                        <h3>Kijk voor inspiratie op</h3>
                        <i class="fab1 fa-facebook-square"></i> <a
                            href="https://www.facebook.com/pages/Voordeellaminaatvloerennl/670239676416851/"
                            target="_blank" rel="nofollow" class="black"> Facebook</a><br>
                        <i class="fab1 fa-instagram"></i> <a href="https://www.instagram.com/voordeellaminaatvloeren/"
                            target="_blank" rel="nofollow" class="black"> Instagram</a><br>
                        <i class="fab1 fa-pinterest"></i> <a href="https://nl.pinterest.com/voorjouwhuis/"
                            target="_blank" rel="nofollow" class="black"> Pinterest</a>
                        <br><br>

                        <h3>Deuren en kasten op maat</h3>
                        <a href="https://www.voordeeldeuren.nl" target="_blank" class="black">Voordeeldeuren</a><br>
                        <a href="https://www.voordeelschuifdeurkast.nl" target="_blank" class="black">Voordeelkasten</a>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>


</div> -->
