import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from '../../shared/services/store.service';
import { CartService } from '../../shared/services/cart.service';
import { FBCReviewsService } from '../../shared/services/fbc-reviews.service';
import { WishlistService } from '../../shared/services/wishlist.service';

import { fbcReviews } from '../../shared/interfaces/fbc-reviews';
import { DropdownService } from '../../shared/services/dropdownmask.service';
import { SearchComponent } from './components/search/search.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { RouterLink } from '@angular/router';
import { DropdownDirective } from 'src/app/shared/directives/dropdown.directive';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { NavComponent } from './components/nav/nav.component';
import { CommonModule } from '@angular/common';
//import { BrowserModule } from '@angular/platform-browser';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        SearchComponent,
        IconComponent,
        RouterLink,
        DropdownDirective,
        DropcartComponent,
        NavComponent,
        CommonModule
    ],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() layout: 'classic' | 'compact' = 'classic';

    // fbcCount: string;
    // fbcTotal: string;
    // fbcScore: string;
    fbcreviews: fbcReviews;
    dropdownShown: boolean;

    constructor(
        public store: StoreService,
        public cart: CartService,
        private httpService: FBCReviewsService,
        public wishlist: WishlistService,
        private dropdownService: DropdownService
    ) {
        this.dropdownService.dropdownShown$.subscribe(
            (dropdownShown) => (this.dropdownShown = dropdownShown)
        );
    }

    async ngOnInit() {
        this.httpService
            .getFBCReviews()
            .subscribe((res) => (this.fbcreviews = res));

        //this.fbcreviews.score = '1';
        //this.fbcreviews.total = '2';
        //this.fbcreviews.count = '3';
        // this.httpService.getFBCReviews().subscribe((res: fbcReviews) => {
        //     this.fbcreviews = res;
        // });
        // this.fbcScore = localStorage.getItem('fbc-score');
        // this.fbcTotal = localStorage.getItem('fbc-total');
        // this.fbcCount = localStorage.getItem('fbc-count');
    }
}
